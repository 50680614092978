import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
// import Button from '@mui/material/Button';
import {
  goToEleventhPage,
  goToHomePage,
  goToNinthPage,
  goToQuizPage,
  goToTenthPage,
  goToTwelfthPage,
  hideCircularLoaderAction,
  showCircularLoaderAction,
} from "../../redux/action-creater/index";
// import "./userinfo.scss"
import { developerJavaScriptScoreAPI, handleDeveloperUser } from "../../api";
import { questions } from "./jsquestion";
import {
  errorToast,
  successToast,
  warningToast,
} from "../../constant/helperFunction";
import CircularColorLoader from "../../component/loader";
// import "./quiz.scss"
// import UserDetailsForm from './UserInformation';

const JavaScriptTest = () => {

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [userAnswers, setUserAnswers] = useState(
    new Array(questions.length).fill(null)
  );
  const [showScore, setShowScore] = useState(false);
  const [timer, setTimer] = useState(0); // 1 hour in seconds
  const [selectedQuestion, setSelectedQuestion] = useState(currentQuestion + 1);
  const [validationMessage, setValidationMessage] = useState("");
  // const navigate=useNavigate()
  // const handleLeaveTest=() => {
  //   dispatch(goToHomePage())
  //         // navigate('/')

  //   // return window.confirm("Are you sure you want to leave test ?")
  //   // // history.go(1);


  // }
  // useEffect(()=>{
  //   window.addEventListener("popstate", handleLeaveTest);
  //   return (()=>{
  //     window.removeEventListener("popstate",handleLeaveTest)
  //   })
  // },[])


  const {
    isHome,
    isNinth,
    isTenth,
    isEleventh,
    isTwelth,
    isDetailed,
    isQuiz,
    isJavaScriptTest,
    circularLoader,
  } = useSelector((state) => state.handlePage);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleBeforeUnload = (e) => {
    // Display a custom message when the user tries to leave the page
    const message = "Are you sure you want to leave this page? Your changes may not be saved.";
    e.returnValue = message; // Standard for most browsers
    return message; // For some older browsers
  };
  useEffect(() => {
    // Add an event listener for the beforeunload event when the component mounts
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    let timerInterval;

    if (timer > 0 && !showScore) {
      timerInterval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    if (timer === 0 && showQuiz) {

      handleClose()
    }




    return () => {
      clearInterval(timerInterval);
    };
  }, [timer, showScore]);


  const handleAnswerClick = (optionIndex) => {
    const newAnswers = [...userAnswers];
    newAnswers[currentQuestion] =
      newAnswers[currentQuestion] === optionIndex ? null : optionIndex;
    setUserAnswers(newAnswers);

    const isCorrect = questions[currentQuestion].options[optionIndex].isCorrect;
    if (isCorrect) {
      setScore(score + 1);
    }
  };

  const handlePreviousClick = () => {
    const prevQuestion = currentQuestion - 1;

    if (prevQuestion >= 0) {
      setCurrentQuestion(prevQuestion);
      setShowScore(false);
      setUserAnswers((prevAnswers) => {
        const updatedAnswers = [...prevAnswers];
        // updatedAnswers[currentQuestion] = null; // Reset the answer for the current question
        return updatedAnswers;
      });
    }
  };

  const handleNextClick = () => {
    const nextQuestion = currentQuestion + 1;

    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
      setShowScore(false);
      setUserAnswers((prevAnswers) => {
        const updatedAnswers = [...prevAnswers];
        // updatedAnswers[currentQuestion] = null; // Reset the answer for the current question
        return updatedAnswers;
      });
    }
  };

  const handleSubmitClick = () => {
    // setShowScore(true);
    // clearInterval(timer); // Stop the timer when the quiz is submitted
  };

  const handleQuestionChange = (event) => {
    const selectedQuestionNumber = parseInt(event.target.value, 10);

    if (
      !isNaN(selectedQuestionNumber) &&
      selectedQuestionNumber > 0 &&
      selectedQuestionNumber <= questions.length
    ) {
      setSelectedQuestion(selectedQuestionNumber);
    }
  };

  const goToSelectedQuestion = () => {
    setCurrentQuestion(selectedQuestion - 1);
    setShowScore(false);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  // user instruction code
  const [userName, setUserName] = useState("");
  const [userGrade, setUserGrade] = useState("");
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [showQuiz, setShowQuiz] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    handleSubmitClick();
    setOpen(true);
    let score = 0;
    console.log(questions, "console");
    console.log(userAnswers, "newAnswers");
    let matchAnsarray = [];
    for (let i = 0; i < questions.length; i++) {
      for (let j = 0; j < questions[i].options.length; j++) {
        if (questions[i].options[j].isCorrect === true) {
          matchAnsarray.push(j);
        }
      }
    }

    for (let i = 0; i < userAnswers.length; i++) {
      if (matchAnsarray[i] === userAnswers[i]) {
        score = score + 1;
      }
    }
    setScore(score);
  };
  const handleClose = () => {
    // alert(`Your Score: ${score}/${questions.length}`)
    dispatch(showCircularLoaderAction());
    
    let getUserInfo = localStorage.getItem("userInfo");
    console.log(getUserInfo)
    // Check if userInfo is not null or undefined before parsing
    getUserInfo = JSON.parse(getUserInfo);

    // Now you can use the parsed userInfo object
    console.log(getUserInfo);
    if (getUserInfo) {

    const response = developerJavaScriptScoreAPI({
      userScore: `${score}/${questions.length}`,
      googleId:getUserInfo.googleId,
      userEmail: getUserInfo.email,
    });

    response
      .then((res) => {
        console.log("res", res);
        if (res.data.statusCode === 200) {
          successToast("Test Submitted!");
          // setShowQuiz(true)
          setShowScore(true);
          clearInterval(timer); // Stop the timer when the quiz is submitted
          navigate("/");
          dispatch(goToHomePage());
          dispatch(hideCircularLoaderAction());

          setOpen(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        errorToast("Something Went Wrong.");
        dispatch(hideCircularLoaderAction());

      });
    }
  };
  const handleCloseWithNode = () => {
    setOpen(false);
  };


  const handleStartQuiz = (e) => {


    let getUserInfo = localStorage.getItem("userInfo");
    console.log(getUserInfo)
    // Check if userInfo is not null or undefined before parsing
    getUserInfo = JSON.parse(getUserInfo);

    // Now you can use the parsed userInfo object
    console.log(getUserInfo);
    if (getUserInfo) {
      dispatch(showCircularLoaderAction());

      const response = handleDeveloperUser({
        userName: getUserInfo.name,
        googleId:getUserInfo.googleId,

        userEmail: getUserInfo.email,
      });
      response
        .then((res) => {
          console.log("res", res);
          if (res.data.statusCode === 200) {
            successToast("Test Started!");
            setShowQuiz(true);
            setTimer(1800)
            dispatch(hideCircularLoaderAction());
          } else if (res.data.statusCode === 405) {
            warningToast("Email Already Exist !");
            dispatch(hideCircularLoaderAction());
          }
        })
        .catch((error) => {
          console.log("error", error);
          dispatch(hideCircularLoaderAction());

          errorToast("Something Went Wrong.");
        });
    }


    // dispatch(showCircularLoaderAction());

    // const response = handleDeveloperUser({
    //   userName,
    //   userGrade,
    //   userPhoneNumber,
    //   userEmail,
    //   userAddress,
    // });
    // response
    //   .then((res) => {
    //     console.log("res", res);
    //     if (res.data.statusCode === 200) {
    //       successToast("Test Started!");
    //       setShowQuiz(true);
    //       setTimer(1800)
    //       dispatch(hideCircularLoaderAction());
    //     } else if (res.data.statusCode === 405) {
    //       warningToast("Email Already Exist !");
    //       dispatch(hideCircularLoaderAction());
    //     }
    //   })
    //   .catch((error) => {
    //     console.log("error", error);
    //     dispatch(hideCircularLoaderAction());

    //     errorToast("Something Went Wrong.");
    //   });
  };

  // user instruction code

  return (
    <>
      {
        circularLoader ? <CircularColorLoader /> :
          <>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>Warning</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are You Sure You want to submit ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Yes</Button>
                <Button onClick={handleCloseWithNode}>No</Button>

                {/* <Button onClick={handleClose}>Yes</Button> */}
              </DialogActions>
            </Dialog>
            {/* // instructios code */}
            {/* <UserDetailsForm /> */}
            {showQuiz ? (
              <div className="quiz">
                {showScore ? (
                  <div className="score">
                    Your Score: {score}/{questions.length}
                  </div>
                ) : (
                  <div className="question">
                    <div style={{ color: timer < 300 ? "red" : "black", textAlign: "right" }}>Time remaining: {formatTime(timer)}</div>

                    <h4>Total question is {questions.length}</h4>
                    <h4 className="current-questio">{questions[currentQuestion].question}</h4>
                    {/* <div className="options">
                {questions[currentQuestion].options.map((option, index) => (
                  <label key={index}>
                    <input
                      type="checkbox"
                      checked={userAnswers[currentQuestion] === index}
                      onChange={() => handleAnswerClick(index)}
                    />
                    {option.text}
                  </label>
                ))}
              </div> */}
                    <div className="options">
                      <div className="options">
                        {questions[currentQuestion].options.map((option, index) => (
                          <label key={index}>
                            <input
                              type="checkbox"
                              checked={userAnswers[currentQuestion] === index}
                              onChange={() => handleAnswerClick(index)}
                            />
                            {option.text}
                          </label>
                        ))}
                      </div>
                    </div>

                    <div className="navigation-buttons">

                      {/* <select value={selectedQuestion} onChange={handleQuestionChange}>
    {Array.from({ length: questions.length }, (_, index) => (
        <option key={index} value={index + 1}>
            Question {index + 1}
        </option>
    ))}
</select> */}
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select Question</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedQuestion}
                          label="select question"
                          onChange={handleQuestionChange}
                        >
                          {/* <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem> */}
                          {Array.from({ length: questions.length }, (_, index) => (
                            <MenuItem key={index} value={index + 1}>
                              Question {index + 1}
                            </MenuItem>
                          ))}
                        </Select>
                        <button onClick={goToSelectedQuestion}>
                          Go to Question
                        </button>
                      </FormControl>

                      {/* <button onClick={handleSubmitClick}>
*/}
                      <button onClick={handlePreviousClick} disabled={currentQuestion === 0}>
                        Previous
                      </button>
                      <button onClick={handleNextClick} disabled={currentQuestion === questions.length - 1}>
                        Next
                      </button>
                      <button onClick={() => {
                        // handleSubmitClick()
                        // navigate("/")
                        // dispatch(goToHomePage())
                        handleClickOpen()

                      }}>

                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="quiz-user-information-container">
                <div className="exam_instruction">
                  <h2>Please follow the given instructios.</h2>
                  <ul>
                    <li>Each question is of one marks.</li>
                    <li>30 minutes of time will be given.</li>
                    <li>Remember if you copy from anywhere else, you are cheating yourself. </li>
                    <li>
                      Question have four option and you have to select only one.
                    </li>
                    <li>You will get result on your mail between 20 Jan 2023 to 21 Jan 2024.</li>
                  </ul>

                  <button onClick={handleStartQuiz} >Start test</button>
                  <button
                    type="button"
                    onClick={() => {
                      navigate("/");
                      dispatch(goToHomePage());
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth", // You can use 'auto' instead of 'smooth' for an instant scroll
                      });
                    }}
                  >
                    Go To Home
                  </button>
                </div>

                {/* <form onSubmit={handleStartQuiz}>
                  <h2>Please fill this form</h2>


                  <label>
                    Name:
                    <br></br>
                    <input
                      type="text"
                      value={userName}
                      onChange={(e) => {
                        setUserName(e.target.value);
                        setValidationMessage("");
                      }}
                      required
                    />
                  </label>
                  <br />
                  <label>
                    College Name
                    <br></br>
                    <input
                      type="text"
                      value={userGrade}
                      onChange={(e) => {
                        setUserGrade(e.target.value);
                        setValidationMessage("");
                      }}
                      required
                    />
                  </label>
                  <br />
                  <label>
                    Phone Number:
                    <br></br>
                    <input
                      type="tel"
                      value={userPhoneNumber}
                      onChange={(e) => {
                        setUserPhoneNumber(e.target.value);
                        setValidationMessage("");
                      }}
                      required
                    />
                  </label>
                  <br />
                  <label>
                    Email:
                    <br></br>
                    <input
                      type="email"
                      value={userEmail}
                      onChange={(e) => {
                        setUserEmail(e.target.value);
                        setValidationMessage("");
                      }}
                      required
                    />
                  </label>
                  <br />
                  <label>
                    Address:
                    <br></br>
                    <textarea
                      value={userAddress}
                      onChange={(e) => {
                        setUserAddress(e.target.value);
                        setValidationMessage("");
                      }}
                      required
                    />
                  </label>
                  <br />

                  <button type="submit">Start Test</button>
                  <button
                    type="button"
                    onClick={() => {
                      navigate("/");
                      dispatch(goToHomePage());
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth", // You can use 'auto' instead of 'smooth' for an instant scroll
                      });
                    }}
                  >
                    Go To Home
                  </button>
                </form> */}
              </div>
            )}
          </>
      }


      {/* // instructios code end */}
    </>
  );
};

export default JavaScriptTest;
