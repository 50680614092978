const ScopeQuestions = [
    {
      question: (
        <pre>
          {`
             if(true){
                var age=23
            }
            console.log(age)
                  `}
        </pre>
      ),
      answer: "23",
      question_type: "Think Output🤔 ",
    },
    {
      question: (
        <pre>
          {`
              if(true){
                let age=23
            }
            console.log(age)
              `}
        </pre>
      ),
      answer: "ReferenceError: age is not defined",
      question_type: "Think Output🤔 ",
    },
    {
        question: (
          <pre>
            {`
                if(true){
                  const age=23
              }
              console.log(age)
                `}
          </pre>
        ),
        answer: "ReferenceError: age is not defined",
        question_type: "Think Output🤔 ",
      },
    {
      question: (
        <pre>
          {`
           
           function myInfo(){
    
            var age=23
        }
        console.log(age)
              `}
        </pre>
      ),
      answer: "ReferenceError: age is not defined",
      question_type: "Think Output🤔 ",
    },
    {
      question:
        <pre>
          {
            `
           
            function outerFunction() {
                var outerVariable = "Hello, ";
                function innerFunction(name) {
                    console.log(outerVariable + name);
                }
                return innerFunction;
            }
            var inner = outerFunction();
            inner("Prakash");

                `
          }
        </pre>,
        answer:"Hello, Prakash",
        question_type: "Think Output🤔 ",
  
    },
    {
      question:
        <pre>
          {
            `
          
            function createCounter() {
                var count = 0;
                function counter() {
                    count++;
                    console.log(count);
                }
                return counter;
            }
                
                var increment = createCounter();
                increment(); 
                increment(); 
                increment();
            `
          }
        </pre>,
        answer:"1, 2, 3",
        question_type: "Think Output🤔 ",
  
  
  
    },
    {
      question:
      <pre>
      {
        `
         
        function outer() {
            var x = 10;
            function inner() {
                console.log(x);
            }
            return inner;
        }
            
        var innerFunc = outer();
        innerFunc();
            `
      }
    </pre>,
    answer:"10",
    question_type: "Think Output🤔 ",
  
  
  
     
    },
    {
      question:
      <pre>
      {
        `
 
        function outer() {
            var x = 10;           
            function inner() {
                console.log(x);
            }
            x = 20;
            return inner;
        }

        var innerFunc = outer();
        innerFunc();
            `
      }
    </pre>,
    answer:"20",
    question_type: "Think Output🤔 ",
  
  
  
    },
    {
      question:
        <pre>
          {
            `
  
            function outer() {

                var x = 10;

                function inner() {
                
                    var y = 5;
                    console.log(x + y);
                
                }
                
                return inner;
                
                }
                
                var innerFunc = outer();
                innerFunc();
  
                `
          }
        </pre>,
        answer:"15",
        question_type: "Think Output🤔 ",
  
  
  
      
    },
    {
      question:
        <pre>
          {
            `

            function outer() {

                var x = 10;
                
                function inner() {
                
                var y = 5;
                
                console.log(x + y);
                
                }
                
                var x = 20;
                
                return inner;
                
                }
                
                var innerFunc = outer();
                
                innerFunc();
                `
          }
        </pre>,
        answer:"25",
        question_type: "Think Output🤔 ",
  
  
  
      
    },
    {
      question:
        <pre>
          {
            `
            function outer() {

                var x = 10;
                
                function inner() {
                
                var y = 5;
                
                console.log(x + y);
                
                x = 20;
                
                }
                
                return inner;
                
                }
                
                var innerFunc = outer();
                
                innerFunc();
                
                innerFunc();
                `
          }
        </pre>,
        answer:"15,25",
        question_type: "Think Output🤔 ",
  
  
  
      
    },
    {
      question: (
        <pre>
          {`
              for (var i = 0; i < 3; i++) {
                  setTimeout(function() {
                      console.log(i); 
                  }, 1000);
              }
              
                  `}
        </pre>
      ),
      answer: "3, 3, 3",
      question_type: "Think Output🤔 ",
    },
    {
      question: (
        <pre>
          {`
              for (let i = 0; i < 3; i++) {
                  setTimeout(function() {
                      console.log(i);
                  }, 1000);
              }
              
          `}
        </pre>
      ),
      answer: "0, 1, 2",
      question_type: "Think Output🤔 ",
    },
    
    
  ];
  export { ScopeQuestions };
  