import React,{useState} from "react";
import { ToastContainer, toast } from "react-toastify";
// import "./style.scss"
import {Programming50} from "./ProblemsName";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import JsCompiler from "../../pages/JsCompiler/Landing";
import Header from "../header";
import Footer from "../footer/Footer";
import LogicBuildingAddComments from "../LogicBuildingAddComment/LogicBuildingAddComments";
const Programming50Begineer = () => {
  const numberOfQuestion = Programming50.length;
  let explanationVisibilityData = {};
  let copyQuestionIndividual = {};
  for (let index = 0; index < numberOfQuestion; index++) {
    explanationVisibilityData[index + 1] = false;
    copyQuestionIndividual[index + 1] = false;
  }


  // pagination
  const questionsPerPage = 10; // Number of questions to display per page
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the index range for questions to display based on the current page
  const startIndex = (currentPage - 1) * questionsPerPage;
  const endIndex = currentPage * questionsPerPage;

  const totalQuestions = Programming50.length;

  // Function to handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setActivePage(page);
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [explanationVisibility, setExplanationVisibility] = useState(
    explanationVisibilityData
  );
  const [copyQuestion, setCopyQuestion] = useState(copyQuestionIndividual);

  const toggleExplanation = (questionNumber) => {
    setExplanationVisibility((prevVisibility) => ({
      ...prevVisibility,
      [questionNumber]: !prevVisibility[questionNumber],
    }));
  };

  
  const [questionInEditor, setQuestionInEditor] = useState("");

  const handleOpenInEditor = (index, questionNumber) => {
    const innerText = document.getElementsByClassName("questions_ui_coding50")[
      index
    ].innerText;
    setQuestionInEditor(innerText);
    // setShowHideEditor(true)
    setOpen(true);
  };
  const [copiedText, setCopiedText] = useState("");
  const [activePage, setActivePage] = useState(1);
  const copyToClipboard = (index, questionNumber) => {
    // const textToCopy = typeof text === 'object' ? JSON.stringify(text)?.props?.children : text;
    // navigator.clipboard.writeText(textToCopy);
    const innerText = document.getElementsByClassName("questions_ui_function")[
      index
    ].innerText;
    navigator.clipboard.writeText(innerText);

    setCopiedText("copied!");
    /* setTimeout(()=>{
      setCopiedText('copy');
    },2000) */
    setCopyQuestion((prevVisibility) => ({
      ...prevVisibility,
      [questionNumber]: !prevVisibility[questionNumber],
    }));
  };

  return (
    <>
    <Header />
      <div className="logic_building_question_container">
        <h3>Total Program/Coding Questions including  <strong>Pattern Problem</strong>: {Programming50.length  } </h3>
        <p style={{textAlign:'center'}}>10 Questions/Page</p>
        
        <div className="programmimg_50_for_begginer">
            <p>Page </p>
          {Array.from({ length: Math.ceil(totalQuestions / questionsPerPage) }).map((_, index) => (
            <button 
            className={activePage === index + 1 ? "activeButtonPagination" : ""}
             key={index + 1} onClick={() => handlePageChange(index + 1)}>
              {index + 1}
            </button>
          ))}
        </div>
        <ol>
          {Programming50.slice(startIndex, endIndex).map((q, index) => {
            const questionNumber = startIndex + index + 1;
            return (
              <>
                <li>
                  <span>
                    Q. {index + 1} {q.question_type}
                  </span>

                  <button
                    className="copyQuestionButton"
                    onClick={() => handleOpenInEditor(index, index + 1)}
                  >
                    {copyQuestion[index + 1] && copiedText
                      ? copiedText
                      : "Open In Editor"}
                  </button>
                  <p className="questions_ui_coding50">{q.question}</p>

                 {/*  <button onClick={() => toggleExplanation(index + 1)}>
                    {explanationVisibility[index + 1]
                      ? "Hide Answer"
                      : "See Answer"}
                  </button> */}
                  {explanationVisibility[index + 1] && (
                    <>
                      <strong style={{ marginLeft: "20px" }}>
                        Expected Output : {q.answer}
                      </strong>
                    </>
                  )}
                </li>
              </>
            );
          })}
        </ol>
        <div className="programmimg_50_for_begginer">
        <p>Page </p>
          {Array.from({ length: Math.ceil(totalQuestions / questionsPerPage) }).map((_, index) => (
            <button
            className={activePage === index + 1 ? "activeButtonPagination" : ""}
             key={index + 1} onClick={() => handlePageChange(index + 1)}>
              {index + 1}
            </button>
          ))}
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="compiler_container">
          <div className="close_compiler_button_container">
            <Button className="close_compiler_button" onClick={handleClose}>
              Close
            </Button>
          </div>

          <JsCompiler questionInEditor={questionInEditor} />
        </div>
      </Modal>
      <LogicBuildingAddComments />
      <Footer />
    </>
  );
};

export default Programming50Begineer;
