const Programming50 = [
    {
      question: (
        <pre>
          {`
             
             function reverseNumber(n) {
                let revNumber = 0;
                while (n > 0) {
                    revNumber = revNumber * 10 + (n % 10);
                    n = Math.floor(n / 10);
                }
                return revNumber;
            }

            console.log(reverseNumber(483457))
            
                  `}
        </pre>
      ),
      question_type: "Write a program to reverse a number",
    },
    {
      question: (
        <pre>
          {`
             
             function fibonacci(number) {
                if (number === 1 || number === 2) {
                    return 1;
                }
                return fibonacci(number - 1) + fibonacci(number - 2);
            }

            function printFibonacciSeries(n) {
                for (let i = 1; i <= n; i++) {
                    console.log(fibonacci(i));
                }
            }
            console.log(fibonacci(12))
            
                  `}
        </pre>
      ),
      question_type: "Write a program to print all elements of the Fibonacci series",
    },
    {
      question: (
        <pre>
          {`
             
             function isPrime(number) {
                if (number <= 1) {
                    return false;
                }
                for (let i = 2; i <= Math.sqrt(number); i++) {
                    if (number % i === 0) {
                        return false;
                    }
                }
                return true;
            }

            console.log(isPrime(24))
            console.log(isPrime(13))

            
                  `}
        </pre>
      ),
      question_type: "Write a program to check whether a number is prime or not",
    },
    {
      question: (
        <pre>
          {`
             
             function swapNumbers(a, b) {
                b = b + a;
                a = b - a;
                b = b - a;
                return [a, b];
            }
            console.log(swapNumbers(3,6))
            
                  `}
        </pre>
      ),
      question_type: "Write a program to swap two numbers without using a third variable",
    },
    {
      question: (
        <pre>
          {`
             
             function factorial(n) {
                if (n === 0 || n === 1) {
                    return 1;
                }
                return n * factorial(n - 1);
            }
            console.log(factorial(5))
            
                  `}
        </pre>
      ),
      question_type: "Write a program to find the factorial of a number",
    },
    {
      question: (
        <pre>
          {`
             
             function gcd(a, b) {
                while (b !== 0) {
                    let temp = b;
                    b = a % b;
                    a = temp;
                }
                return a;
            }
            console.log(gcd(24,36))
            
                  `}
        </pre>
      ),
      question_type: "Write a program to find the GCD of two numbers",
    },
    {
      question: (
        <pre>
          {`
             
             function isPalindrome(num) {
                const strNum = num.toString();
                const reversedStr = strNum.split('').reverse().join('');
                return strNum === reversedStr;
            }
            console.log(isPalindrome("abcba"))
            console.log(isPalindrome("abcbab"))

            
                  `}
        </pre>
      ),
      question_type: "Write a program to check if a number is palindrome or not",
    },
    {
      question: (
        <pre>
          {`
             
             function isArmstrong(num) {
                const strNum = num.toString();
                const length = strNum.length;
                let sum = 0;
                for (let i = 0; i < length; i++) {
                    sum += Math.pow(parseInt(strNum[i]), length);
                }
                return sum === num;
            }

            console.log(isArmstrong(153))
            console.log(isArmstrong(112))

            
                  `}
        </pre>
      ),
      question_type: "Write a program to find Armstrong numbers in a given range",
    },
    {
      question: (
        <pre>
          {`
             
             function sumOfDigits(num) {
                let sum = 0;
                while (num > 0) {
                    sum += num % 10;
                    num = Math.floor(num / 10);
                }
                return sum;
            }
            console.log(sumOfDigits(45643))
            console.log(sumOfDigits(1234))
            
                  `}
        </pre>
      ),
      question_type: "Write a program to find the sum of digits of a number",
    },
    {
      question: (
        <pre>
          {`
            
             function findPairWithSum(array, target) {
                const map = new Map();
                for (let i = 0; i < array.length; i++) {
                    const difference = target - array[i];
                    if (map.has(array[i])) {
                        return [map.get(array[i]), i];
                    } else {
                        map.set(difference, i);
                    }
                }
                return [];
            }
            console.log(findPairWithSum([2,5,3,7,3],12))
            
                  `}
        </pre>
      ),
      question_type: "Write a program to find the position of a pair of numbers whose sum is equal to a given sum",
    },
    {
        question: (
            <pre>
                {`
             
             function isLeapYear(year) {
                 let isLeap = false;
                 if (year % 4 === 0) {
                     if (year % 100 === 0) {
                         if (year % 400 === 0) {
                             isLeap = true;
                         } else {
                             isLeap = false;
                         }
                     } else {
                         isLeap = true;
                     }
                 } else {
                     isLeap = false;
                 }

                 if (isLeap)
                     console.log("Leap year");
                 else
                     console.log("Not a leap year");
             }

             isLeapYear(1992);
             isLeapYear(2000);
             isLeapYear(2001);
             isLeapYear(2002);
             isLeapYear(2004);
          `}
            </pre>
        ),
        question_type: "Check whether a year is leap year or not",
    },
    {
        question: (
            <pre>
                {`
              
              function checkVowelOrConsonant(ch) {
                  if (ch === 'a' || ch === 'e' || ch === 'i' || ch === 'o' || ch === 'u' || ch === 'A' || ch === 'E' || ch === 'I' || ch === 'O' || ch === 'U') {
                      console.log("Vowel");
                  } else if ((ch >= 'a' && ch <= 'z') || (ch >= 'A' && ch <= 'Z')) {
                      console.log("Consonant");
                  } else {
                      console.log("Symbols");
                  }
              }
              
              checkVowelOrConsonant('a');
          `}
            </pre>
        ),
        question_type: "Check whether a character is a vowel or consonant",
    },
    {
        question: (
            <pre>
                {`
             
              function binarySearch(arr, num) {
                  let start = 0;
                  let end = arr.length - 1;
                  let mid;
                  while (start <= end) {
                      mid = Math.floor((start + end) / 2);
                      if (arr[mid] === num) {
                          return mid;
                      } else if (arr[mid] < num) {
                          start = mid + 1;
                      } else {
                          end = mid - 1;
                      }
                  }
                  return -1;
              }
              
              const arr = [1, 3, 5, 7, 9];
              console.log(binarySearch(arr, 5));
          `}
            </pre>
        ),
        question_type: "Implement the Binary Search Algorithm in JavaScript",
    },
    {
        question: (
            <pre>
                {`
             
              function isAnagram(str1, str2) {
                  const sortedStr1 = str1.split('').sort().join('');
                  const sortedStr2 = str2.split('').sort().join('');
                  return sortedStr1 === sortedStr2;
              }
              
              const str1 = "listen";
              const str2 = "silent";
              console.log(isAnagram(str1, str2) ? "Anagrams" : "Not Anagrams");
          `}
            </pre>
        ),
        question_type: "Check if two strings are anagrams of each other",
    },
    {
        question: (
            <pre>
                {`
             
              function bubbleSort(arr) {
                  const len = arr.length;
                  let swapped;
                  do {
                      swapped = false;
                      for (let i = 0; i < len - 1; i++) {
                          if (arr[i] > arr[i + 1]) {
                              const temp = arr[i];
                              arr[i] = arr[i + 1];
                              arr[i + 1] = temp;
                              swapped = true;
                          }
                      }
                  } while (swapped);
                  return arr;
              }
              
              const numbers = [3, 2, 5, 1, 4];
              console.log(bubbleSort(numbers));
          `}
            </pre>
        ),
        question_type: "Write the Bubble Sort Algorithm",
    },
    {
        question: (
            <pre>
                {`
             
              function mergeSort(arr) {
                  if (arr.length <= 1) return arr;
                  const mid = Math.floor(arr.length / 2);
                  const left = mergeSort(arr.slice(0, mid));
                  const right = mergeSort(arr.slice(mid));
                  return merge(left, right);
              }
              
              function merge(left, right) {
                  let result = [];
                  let leftIndex = 0;
                  let rightIndex = 0;
              
                  while (leftIndex < left.length && rightIndex < right.length) {
                      if (left[leftIndex] < right[rightIndex]) {
                          result.push(left[leftIndex]);
                          leftIndex++;
                      } else {
                          result.push(right[rightIndex]);
                          rightIndex++;
                      }
                  }
                  return result.concat(left.slice(leftIndex)).concat(right.slice(rightIndex));
              }
              
              const arr = [4, 2, 7, 1, 3];
              console.log(mergeSort(arr));
          `}
            </pre>
        ),
        question_type: "Implement the Merge Sort Algorithm",
    },
    {
        question: (
            <pre>
                {`
             
              function addMatrices(matrix1, matrix2) {
                  const result = [];
                  for (let i = 0; i < matrix1.length; i++) {
                      result[i] = [];
                      for (let j = 0; j < matrix1[i].length; j++) {
                          result[i][j] = matrix1[i][j] + matrix2[i][j];
                      }
                  }
                  return result;
              }
              
              const matrix1 = [[1, 2, 3], [4, 5, 6], [7, 8, 9]];
              const matrix2 = [[9, 8, 7], [6, 5, 4], [3, 2, 1]];
              console.log(addMatrices(matrix1, matrix2));
          `}
            </pre>
        ),
        question_type: "Write a program to add two matrices in JavaScript",
    },
    {
        question: (
            <pre>
                {`
             
              function decimalToBinary(num) {
                  let binary = "";
                  while (num > 0) {
                      binary = (num % 2) + binary;
                      num = Math.floor(num / 2);
                  }
                  return binary;
              }
              
              console.log(decimalToBinary(10));
          `}
            </pre>
        ),
        question_type: "Write a program to convert decimal to binary",
    },
    {
        question: (
            <pre>
                {`
              
              function decimalToHexadecimal(num) {
                  const hexChars = '0123456789ABCDEF';
                  let hexadecimal = '';
                  while (num > 0) {
                      hexadecimal = hexChars[num % 16] + hexadecimal;
                      num = Math.floor(num / 16);
                  }
                  return hexadecimal;
              }
              
              console.log(decimalToHexadecimal(255));
          `}
            </pre>
        ),
        question_type: "Write a program to convert decimal to hexadecimal",
    },
    {
        question: (
            <pre>
                {`
              
              function sumOfNaturalNumbers(n) {
                  return (n * (n + 1)) / 2;
              }
              
              console.log(sumOfNaturalNumbers(5));
          `}
            </pre>
        ),
        question_type: "Write a program to find the sum of n natural numbers",
    },
    {
        question: (
            <pre>
                {`
            
              function isPerfectNumber(number) {
                  let sum = 0;
                  for (let i = 1; i <= number / 2; i++) {
                      if (number % i === 0) {
                          sum += i;
                      }
                  }
                  if (sum === number) {
                      console.log("Perfect number");
                  } else {
                      console.log("Not a perfect number");
                  }
              }
              
              isPerfectNumber(28);
          `}
            </pre>
        ),
        question_type: "Check whether a number is a perfect number or not",
    },
    {
        question: (
            <pre>
                {`
            
              function getGCD(num1, num2) {
                  while (num2 > 0) {
                      let temp = num2;
                      num2 = num1 % num2;
                      num1 = temp;
                  }
                  return num1;
              }
              
              function getLCM(num1, num2) {
                  return (num1 * num2) / getGCD(num1, num2);
              }
              
              console.log(getLCM(12, 15));
          `}
            </pre>
        ),
        question_type: "Find the LCM of two numbers",
    },
    {
        question: (
            <pre>
                {`
             
              function calculateAverage(numbers) {
                  const sum = numbers.reduce((acc, curr) => acc + curr, 0);
                  return sum / numbers.length;
              }
              
              const numbers = [5, 10, 15, 20, 25];
              console.log(calculateAverage(numbers));
          `}
            </pre>
        ),
        question_type: "Calculate the average of n numbers",
    },
    {
        question: (
            <pre>
                {`
           
              function sumOfDigits(number) {
                  let sum = 0;
                  while (number > 0) {
                      sum += number % 10;
                      number = Math.floor(number / 10);
                  }
                  return sum;
              }
              
              console.log(sumOfDigits(12345));
          `}
            </pre>
        ),
        question_type: "Calculate the sum of digits of a given number",
    },
    {
        question: (
            <pre>
                {`
            
              function powerOfNumber(number, exponent) {
                  let result = 1;
                  for (let i = 0; i < exponent; i++) {
                      result *= number;
                  }
                  return result;
              }
              
              console.log(powerOfNumber(2, 5));
          `}
            </pre>
        ),
        question_type: "Calculate the power of a number",
    },
    {
        question: (
            <pre>
                {`
              
              function subtractMatrices(matrix1, matrix2) {
                  const result = [];
                  for (let i = 0; i < matrix1.length; i++) {
                      result[i] = [];
                      for (let j = 0; j < matrix1[i].length; j++) {
                          result[i][j] = matrix1[i][j] - matrix2[i][j];
                      }
                  }
                  return result;
              }
              
              const matrix1 = [[1, 2, 3], [4, 5, 6], [7, 8, 9]];
              const matrix2 = [[9, 8, 7], [6, 5, 4], [3, 2, 1]];
              console.log(subtractMatrices(matrix1, matrix2));
          `}
            </pre>
        ),
        question_type: "Subtract two matrices",
    },
    {
        question: (
            <pre>
                {`
            
              function multiplyMatrices(matrix1, matrix2) {
                  const result = [];
                  for (let i = 0; i < matrix1.length; i++) {
                      result[i] = [];
                      for (let j = 0; j < matrix2[0].length; j++) {
                          let sum = 0;
                          for (let k = 0; k < matrix1[0].length; k++) {
                              sum += matrix1[i][k] * matrix2[k][j];
                          }
                          result[i][j] = sum;
                      }
                  }
                  return result;
              }
              
              const matrix1 = [[1, 2], [3, 4], [5, 6]];
              const matrix2 = [[7, 8, 9], [10, 11, 12]];
              console.log(multiplyMatrices(matrix1, matrix2));
          `}
            </pre>
        ),
        question_type: "Multiply two matrices",
    },
    {
        question: (
            <pre>
                {`
          
              function reverseString(str) {
                  return str.split('').reverse().join('');
              }
              
              console.log(reverseString("HelloWorld"));
          `}
            </pre>
        ),
        question_type: "Reverse a string",
    },
    {
        question: (
            <pre>
                {`
          
              function selectionSort(arr) {
                  for (let i = 0; i < arr.length - 1; i++) {
                      let minIndex = i;
                      for (let j = i + 1; j < arr.length; j++) {
                          if (arr[j] < arr[minIndex]) {
                              minIndex = j;
                          }
                      }
                      if (minIndex !== i) {
                          [arr[i], arr[minIndex]] = [arr[minIndex], arr[i]];
                      }
                  }
                  return arr;
              }
              
              const arr = [5, 3, 7, 2, 9];
              console.log(selectionSort(arr));
          `}
            </pre>
        ),
        question_type: "Implement Selection Sort in JavaScript",
    },
    {
        question: (
            <pre>
                {`
             
              function linearSearch(arr, target) {
                  for (let i = 0; i < arr.length; i++) {
                      if (arr[i] === target) {
                          return i;
                      }
                  }
                  return -1;
              }
              
              const array = [1, 3, 7, 2, 9, 4];
              const target = 7;
              console.log(linearSearch(array, target));
          `}
            </pre>
        ),
        question_type: "Linearly search an element",
    },
    {
        question: (
            <pre>
                {`
             
              class SinglyLinkedList {
                  count(begin) {
                      let count = 0;
                      if (begin === null) {
                          console.log("Empty");
                          return;
                      }
                      let p = begin;
                      while (p !== null) {
                          count++;
                          p = p.next;
                      }
                      console.log("Length of the Linked List: ", count);
                  }
              }
          `}
            </pre>
        ),
        question_type: "Find the length of a Linked List",
    },
    {
        question: (
            <pre>
                {`
             
              function reverseLinkedList(list) {
                  let prev = null;
                  let current = list.head;
                  let next = null;
                  while (current !== null) {
                      next = current.next;
                      current.next = prev;
                      prev = current;
                      current = next;
                  }
                  list.head = prev;
              }
          `}
            </pre>
        ),
        question_type: "Reverse a Linked List",
    },
    {
        question: (
            <pre>
                {`
            
              class LinkedList {
                  isCyclic() {
                      let fast = this.head;
                      let slow = this.head;
                      while (fast !== null && fast.next !== null) {
                          fast = fast.next.next;
                          slow = slow.next;
                          if (fast === slow) {
                              return true;
                          }
                      }
                      return false;
                  }
              }
          `}
            </pre>
        ),
        question_type: "Check whether a LinkedList contains a loop",
    },
    {
        question: (
            <pre>
                {`
            
              function countWords(str) {
                  const words = str.split(" ");
                  const map = new Map();
                  for (const word of words) {
                      map.set(word, (map.get(word) || 0) + 1);
                  }
                  return map;
              }
          `}
            </pre>
        ),
        question_type: "Count the number of words in a string using HashMap",
    },
    {
        question: (
            <pre>
                {`
             
              function findDuplicates(str) {
                  const charMap = {};
                  const duplicates = [];
                  for (const char of str) {
                      charMap[char] = charMap[char] + 1 || 1;
                      if (charMap[char] > 1 && !duplicates.includes(char)) {
                          duplicates.push(char);
                      }
                  }
                  return duplicates;
              }
          `}
            </pre>
        ),
        question_type: "Find duplicate characters in a string",
    },
    {
        question: (
            <pre>
                {`
            
              function findSecondHighest(arr) {
                  let largest = arr[0];
                  let secondLargest = arr[0];
                  for (const num of arr) {
                      if (num > largest) {
                          secondLargest = largest;
                          largest = num;
                      } else if (num > secondLargest && num < largest) {
                          secondLargest = num;
                      }
                  }
                  return secondLargest;
              }
          `}
            </pre>
        ),
        question_type: "Find the second highest number in an array",
    },
    {
        question: (
            <pre>
                {`
         
              function removeSpaces(str) {
                  return str.replace(/\\s/g, "");
              }
          `}
            </pre>
        ),
        question_type: "Remove all spaces from a string",
    },
    {
        question: (
            <pre>
                {`
          
              function firstNonRepeatingChar(str) {
                  const charMap = {};
                  for (const char of str) {
                      charMap[char] = charMap[char] + 1 || 1;
                  }
                  for (const char of str) {
                      if (charMap[char] === 1) {
                          return char;
                      }
                  }
                  return null;
              }
          `}
            </pre>
        ),
        question_type: "Find the first non-repeating character of a string",
    },
    {
        question: (
            <pre>
                {`
           
              function transposeMatrix(matrix) {
                  const transposed = [];
                  for (let i = 0; i < matrix[0].length; i++) {
                      transposed[i] = [];
                      for (let j = 0; j < matrix.length; j++) {
                          transposed[i][j] = matrix[j][i];
                      }
                  }
                  return transposed;
              }
          `}
            </pre>
        ),
        question_type: "Transpose a matrix",
    },
    {
        question: (
            <pre>
                {`
           
              function printSubstrings(str) {
                  for (let i = 0; i < str.length; i++) {
                      for (let j = i + 1; j <= str.length; j++) {
                          console.log(str.substring(i, j));
                      }
                  }
              }
          `}
            </pre>
        ),
        question_type: "Print all substrings of a string",
    },
    {
        question: (
            <pre>
              {`
             
             function separateZeroesAndOnes(arr) {
                 let count = 0;
                 for (let i = 0; i < arr.length; i++) {
                     if (arr[i] === 0) {
                         count++;
                     }
                 }
                 for (let i = 0; i < count; i++) {
                     arr[i] = 0;
                 }
                 for (let i = count; i < arr.length; i++) {
                     arr[i] = 1;
                 }
                 return arr;
             }
            
                  `}
            </pre>
        ),
        question_type: "Separate 0's and 1's in an array",
    },
    {
        question: (
            <pre>
              {`
             
             function findMissingNumber(arr) {
                 const n = arr.length + 1;
                 const sum = (n * (n + 1)) / 2;
                 let restSum = 0;
                 for (let i = 0; i < arr.length; i++) {
                     restSum += arr[i];
                 }
                 const missingNumber = sum - restSum;
                 return missingNumber;
             }
            
                  `}
            </pre>
        ),
        question_type: "Find the missing number in an array",
    },
    {
        question: (
            <pre>
              {`
             
             function findSubarraySum(arr, target) {
                 let left = 0;
                 let right = 1;
                 let sum = arr[left] + arr[right];
                 while (right < arr.length) {
                     if (sum === target) {
                         return [left + 1, right + 1];
                     } else if (sum < target) {
                         right++;
                         sum += arr[right];
                     } else {
                         sum -= arr[left];
                         left++;
                     }
                 }
                 return -1;
             }
            
                  `}
            </pre>
        ),
        question_type: "Find the subarray with a given sum",
    },
    {
        question: (
            <pre>
              {`
             
             function countFrequency(arr) {
                 const frequency = {};
                 for (let i = 0; i < arr.length; i++) {
                     if (frequency[arr[i]]) {
                         frequency[arr[i]]++;
                     } else {
                         frequency[arr[i]] = 1;
                     }
                 }
                 return frequency;
             }
            
                  `}
            </pre>
        ),
        question_type: "Count the frequency of each element in the array",
    },
    {
        question: (
            <pre>
              {`
             
             function findFirstRepeatingElement(arr) {
                 const set = new Set();
                 let firstRepeating = -1;
                 for (let i = 0; i < arr.length; i++) {
                     if (set.has(arr[i])) {
                         firstRepeating = arr[i];
                         break;
                     } else {
                         set.add(arr[i]);
                     }
                 }
                 return firstRepeating;
             }
            
                  `}
            </pre>
        ),
        question_type: "Find the first repeating element in the array",
    },
    {
        question: (
            <pre>
              {`
             
             function findMaxSubArray(arr) {
                 let maxSum = arr[0];
                 let currentSum = arr[0];
                 for (let i = 1; i < arr.length; i++) {
                     currentSum = Math.max(arr[i], currentSum + arr[i]);
                     maxSum = Math.max(maxSum, currentSum);
                 }
                 return maxSum;
             }
            
                  `}
            </pre>
        ),
        question_type: "Find the maximum subarray sum",
    },
    {
        question: (
            <pre>
              {`
             
             function rotateArray(arr, k) {
                 const n = arr.length;
                 const rotated = [];
                 for (let i = 0; i < n; i++) {
                     rotated[(i + k) % n] = arr[i];
                 }
                 return rotated;
             }
            
                  `}
            </pre>
        ),
        question_type: "Rotate an array by k positions",
    },
    {
        question: (
            <pre>
              {`
             
             function findPeakElements(arr) {
                 const peaks = [];
                 for (let i = 1; i < arr.length - 1; i++) {
                     if (arr[i] > arr[i - 1] && arr[i] > arr[i + 1]) {
                         peaks.push(arr[i]);
                     }
                 }
                 return peaks;
             }
            
                  `}
            </pre>
        ),
        question_type: "Find the peak elements in the array",
    },
    {
        question: (
            <pre>
              {`
             
             function getMiddle(head) {
                 let slowPtr = head;
                 let fastPtr = head;
                 while (fastPtr !== null && fastPtr.next !== null) {
                     slowPtr = slowPtr.next;
                     fastPtr = fastPtr.next.next;
                 }
                 return slowPtr.val;
             }
            
                  `}
            </pre>
        ),
        question_type: "Find the middle element of a linked list in one pass",
    },
    {
        question: (
            <pre>
              {`
             
             function addTwoNumbers(l1, l2) {
                 let carry = 0;
                 let dummy = new ListNode(-1);
                 let current = dummy;
                 while (l1 !== null || l2 !== null || carry !== 0) {
                     let sum = carry;
                     if (l1 !== null) {
                         sum += l1.val;
                         l1 = l1.next;
                     }
                     if (l2 !== null) {
                         sum += l2.val;
                         l2 = l2.next;
                     }
                     carry = Math.floor(sum / 10);
                     sum %= 10;
                     current.next = new ListNode(sum);
                     current = current.next;
                 }
                 return dummy.next;
             }
            
                  `}
            </pre>
        ),
        question_type: "Add two numbers represented by two linked lists",
    },
    {
        question: (
            <pre>
              {`
             
                /*Star Pattern 1

                * 

                * * 

                * * * 

                * * * * 

                * * * * *   */
                function printStarPattern(rows) {
                    for (let i = 1; i <= rows; ++i) { // Outer loop for rows
                        let row = '';
                        for (let j = 1; j <= i; ++j) { // Inner loop for columns
                            row += '* '; // Append *
                        }
                        console.log(row); // Print row
                    }
                }
                
                const rows = 5;
                printStarPattern(rows);
            
                  `}
            </pre>
        ),
        question_type: "Star Pattern 1",
    },
    {
        question: (
            <pre>
              {`
             
                /*Star Pattern 2

                * * * * * 

                * * * * 

                * * * 

                * * 

                *   */
                function printStarPattern(rows) {
                    for (let i = rows; i >= 1; --i) { // Outer loop for rows
                        let row = '';
                        for (let j = 1; j <= i; ++j) { // Inner loop for columns
                            row += '* '; // Append *
                        }
                        console.log(row); // Print row
                    }
                }
                
                const rows = 5;
                printStarPattern(rows);
            
                  `}
            </pre>
        ),
        question_type: "Star Pattern 2",
    },
    {
        question: (
            <pre>
              {`
             
                /*Star Pattern 3

                * 

                * * 

                * * * 

                * * * * 

                * * * * * 

                * * * * 

                * * * 

                * * 

                *   */
                function startPattern(rows){
                    for (let i = 0; i < rows; i++) { // For loop for rows
                        let row = '';
                        for (let j = 0; j <= i; j++) { // For loop for columns
                          row += '* '; // Prints * and blank space
                        }
                        console.log(row); // Prints the row
                      }
                    
                      for (let i = rows - 1; i >= 0; i--) { // For loop for rows
                        let row = '';
                        for (let j = 0; j < i; j++) { // For loop for columns
                          row += '* '; // Prints * and blank space
                        }
                        console.log(row); // Prints the row
                      }
                    
                    }
                    startPattern(10)
            
                  `}
            </pre>
        ),
        question_type: "Star Pattern 3",
    },
    {
        question: (
            <pre>
              {`
             
                /*Star Pattern 4

                  * 

                * * 
       
              * * * 
       
            * * * * 
       
          * * * * *   */
                function printStars(n) {
                    for (let i = 0; i < n; i++) {
                        let row = '';
                        for (let j = 2 * (n - i); j >= 0; j--) { // For loop for spaces
                            row += ' '; // Print spaces
                        }
                        for (let j = 0; j <= i; j++) { // For loop for stars
                            row += '* '; // Print stars
                        }
                        console.log(row); // Print the row
                    }
                }
                
                const n = 5; // Number of rows
                printStars(n);                
            
                  `}
            </pre>
        ),
        question_type: "Star Pattern 4",
    },
    {
        question: (
            <pre>
              {`
             
                /*Star Pattern 5

              * * * * *

                * * * *
              
                  * * *
              
                    * *
              
                      *  */
                function starPattern(rows){
                    for (let i = rows; i >= 1; i--) {
                  let row = '';
                  for (let j = rows; j > i; j--) {
                    row += ' '; // Print blank space
                  }
                  for (let k = 1; k <= i; k++) {
                    row += '*'; // Print *
                  }
                  console.log(row); // Print the row
                }
                }
                starPattern(5)          
            
                  `}
            </pre>
        ),
        question_type: "Star Pattern 5",
    },
    {
        question: (
            <pre>
              {`
             
                /*Star Pattern 7

                 * 

                * * 
             
               * * * 
             
              * * * * 
             
             * * * * *    */
                function printTriangle(n) {
                    for (let i = 0; i < n; i++) {
                      let row = '';
                      for (let j = n - i; j > 1; j--) {
                        row += ' '; // Print blank space
                      }
                      for (let j = 0; j <= i; j++) {
                        row += '* '; // Print star
                      }
                      console.log(row); // Print the row
                    }
                  }
                  
                  const n = 5;
                  printTriangle(n);        
            
                  `}
            </pre>
        ),
        question_type: "Star Pattern 7",
    },
    {
        question: (
            <pre>
              {`
             
                /*Star Pattern 8

               * * * * * 

                * * * * 
              
                 * * * 
              
                  * * 
              
                   *  */
                function printTriangle(rows) {
                    for (let i = 0; i <= rows - 1; i++) { // For loop for rows
                    let row = '';
                    for (let j = 0; j <= i; j++) { // For loop for columns
                      row += ' '; // Print blank space
                    }
                    for (let k = 0; k <= rows - 1 - i; k++) {
                      row += '* '; // Print * and blank space
                    }
                    console.log(row); // Print the row
                  }
                }
                
                const n = 5;
                printTriangle(n )
            
                  `}
            </pre>
        ),
        question_type: "Star Pattern 8",
    },
    {
        question: (
            <pre>
              {`
             
             /*Star Pattern 9

              *

             ***
          
            *****
          
           *******
          
          *********
          
           *******
          
            *****
          
             ***
          
              *     */
             function printTriangle(n) {
                for (let i = 1; i <= n; i++) {
                 let row = '';
                 // Add leading spaces
                 for (let j = 1; j <= n - i; j++) {
                   row += ' ';
                 }
                 // Add stars
                 for (let k = 1; k <= 2 * i - 1; k++) {
                   row += '*';
                 }
                 console.log(row);
               }
             
               // Lower star pyramid
               for (let i = n - 1; i >= 1; i--) {
                 let row = '';
                 // Add leading spaces
                 for (let j = 1; j <= n - i; j++) {
                   row += ' ';
                 }
                 // Add stars
                 for (let k = 1; k <= 2 * i - 1; k++) {
                   row += '*';
                 }
                 console.log(row);
               }
             }
             
             const n = 5;
             printTriangle(n);
             
            
                  `}
            </pre>
        ),
        question_type: "Star Pattern 9",
    },
    {
        question: (
            <pre>
              {`
             
             /*Star Pattern 10

           * * * * * 

            * * * * 

             * * * 

              * * 

               * 

               * 

              * * 

             * * * 

            * * * * 

           * * * * *   */
             function printTriangle(rows) {
                for (let i = 0; i <= rows - 1; i++) {
                  let row = '';
                  // Print blank spaces
                  for (let j = 0; j < i; j++) {
                    row += ' ';
                  }
                  // Print stars and blank spaces
                  for (let k = i; k <= rows - 1; k++) {
                    row += '* ';
                  }
                  console.log(row); // Print the row
                }
              
                // Lower Pyramid
                for (let i = rows - 1; i >= 0; i--) {
                  let row = '';
                  // Print spaces
                  for (let j = 0; j < i; j++) {
                    row += ' ';
                  }
                  // Print stars and spaces
                  for (let k = i; k <= rows - 1; k++) {
                    row += '* ';
                  }
                  console.log(row); // Print the row
                }
              }
              
              const n = 5;
              printTriangle(n);
             
            
                  `}
            </pre>
        ),
        question_type: "Star Pattern 10",
    },
    {
        question: (
            <pre>
              {`
             
             /*Star Pattern 10

               *

             *
       
           *
       
         *
       
       *             */
             
                    function printPattern() {
                        // Outer loop for rows
                        for (let i = 1; i <= 5; i++) {
                            let row = '';
                            // Inner loop for spaces
                            for (let j = 0; j < 5 - i; j++) {
                                row += '  '; // Print blank space (two spaces for formatting)
                            }
                            row += '*'; // Print star
                            console.log(row); // Print the row
                        }
                    }

                    
                    printPattern();

             
            
                  `}
            </pre>
        ),
        question_type: "Star Pattern 11",
    },
    {
        question: (
            <pre>
              {`
             
             /*Star Pattern 12

             *     *

              *   *
            
               * *
            
                *
            
               * *
            
              *   *
             
             *     *  */
             
             // Define the function to print the pattern
             function printPattern(n) {
               // Upper V pattern
               for (let i = n; i >= 1; i--) {
                 let row = '';
                 // Print leading spaces
                 for (let j = i; j < n; j++) {
                   row += ' ';
                 }
                 // Print stars or spaces based on logic
                 for (let j = 1; j <= 2 * i - 1; j++) {
                   if (j === 1 || j === 2 * i - 1) {
                     row += '*';
                   } else {
                     row += ' ';
                   }
                 }
                 console.log(row);
               }
             
               // Lower Inverted V pattern
               for (let i = 2; i <= n; i++) {
                 let row = '';
                 // Print leading spaces
                 for (let j = i; j < n; j++) {
                   row += ' ';
                 }
                 // Print stars or spaces based on logic
                 for (let j = 1; j <= 2 * i - 1; j++) {
                   if (j === 1 || j === 2 * i - 1) {
                     row += '*';
                   } else {
                     row += ' ';
                   }
                 }
                 console.log(row);
               }
             
             }
             
             // Call the function to print the pattern
             printPattern(5);
             

             
            
                  `}
            </pre>
        ),
        question_type: "Star Pattern 12",
    },
    {
        question: (
            <pre>
              {`
             
             /*Number Pattern

                1 

                1 2 

                1 2 3 

                1 2 3 4 

                1 2 3 4 5 */ 
             
                
                function printPattern(rows) {
                  for (let i = 1; i <= rows; i++) {
                    let row = '';
                    for (let j = 1; j <= i; j++) {
                      row += j + ' '; // Print j value and space
                    }
                    console.log(row);
                  }
                
                }
                
                
                printPattern(5);

             
            
                  `}
            </pre>
        ),
        question_type: "Number Patter 1",
    },
    {
        question: (
            <pre>
              {`
             
             /*Number Pattern 2 (Floyd's Triangle)

             1 
             
             2 3 
             
             4 5 6 
             
             7 8 9 10 
             
             11 12 13 14 15  */
             
                
             // Define the function to print the pattern
             function printPattern(rows) {
               // Define the number of rows
             
             let k = 1;
             
             // Loop to iterate through each row
             for (let i = 1; i <= rows; i++) {
                 let row = '';
             
                 // Loop to iterate through each column in the current row
                 for (let j = 1; j <= i; j++) {
                     row += k++ + ' '; // Floyd's triangle logic (print k value and increment on every iteration)
                 }
             
                 console.log(row); // Print the row
             }
             
             
             }
             
             // Call the function to print the pattern
             printPattern(5);

             
            
                  `}
            </pre>
        ),
        question_type: "Number Pattern 2 (Floyd's Triangle)",
    },
    {
        question: (
            <pre>
              {`
             
             /*Number Pattern 3 (Pascal's Triangle)

               1

             1   1

           1   2   1

         1   3   3   1

       1   4   6   4   1

     1   5  10  10   5   1 */
             
                
     function printPascalsTriangle(x) {
        for (let i = 0; i < x; i++) {
            let num = 1;
            let row = '';
    
            // Print leading spaces
            for (let s = 0; s < (x - i) * 2; s++) {
                row += ' ';
            }
    
            // Calculate and print numbers for the current row
            for (let j = 0; j <= i; j++) {
                row += num.toString().padStart(4, ' ');
                num = Math.floor(num * (i - j) / (j + 1));
            }
    
            console.log(row);
        }
    }
    
    const x = 6;
    printPascalsTriangle(x);
    

             
            
                  `}
            </pre>
        ),
        question_type: "Number Pattern 3 (Pascal's Triangle)",
    },
    {
        question: (
            <pre>
              {`
             
             /*Number Pattern 22

             10101
             
             01010
             
             10101
             
             01010
             
             10101  */
             
                
             function numberPattern(rows) {
                for (let i = 1; i <= rows; i++) {
                let num;
            
                if (i % 2 === 0) {
                  num = 0;
                } else {
                  num = 1;
                }
            
                let row = '';
                for (let j = 1; j <= rows; j++) {
                  row += num;
                  num = (num === 0) ? 1 : 0;
                }
            
                console.log(row);
              }
            }
            
            const x = 6;
            numberPattern(x);
    

             
            
                  `}
            </pre>
        ),
        question_type: "Number Pattern 3 (Pascal's Triangle)",
    },
  
];

export { Programming50 };
