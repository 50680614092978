import { ToastContainer, toast } from 'react-toastify';


const successToast=(message)=>{
   return toast.success(`${message}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
        closeButton: false,
        style: {
          // background: '#4CAF50', // Background color
          color: '#000',         // Text color
          borderRadius: '8px',   // Border radius
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Box shadow/
          fontSize: '12px',      // Font size
          whiteSpace: 'nowrap',  // Prevent line breaks
  
        },
      });
}

const infoToast=(message)=>{
    return toast.info(`${message}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
        closeButton: false,
        style: {
          // background: '#4CAF50', // Background color
          color: '#000',         // Text color
          borderRadius: '8px',   // Border radius
          // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Box shadow
          fontSize: '12px',      // Font size
          whiteSpace: 'nowrap',  // Prevent line breaks
  
        },
      });
}
const warningToast=(message)=>{
    return toast.warning(`${message}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
        closeButton: false,
        style: {
          // background: '#4CAF50', // Background color
          color: '#000',         // Text color
          borderRadius: '8px',   // Border radius
          // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Box shadow
          fontSize: '12px',      // Font size
          whiteSpace: 'nowrap',  // Prevent line breaks
  
        },
      });

}
const errorToast=(message)=>{
    return toast.error(`${message}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
        closeButton: false,
        style: {
          // background: '#4CAF50', // Background color
          color: '#000',         // Text color
          borderRadius: '8px',   // Border radius
          // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Box shadow
          fontSize: '12px',      // Font size
          whiteSpace: 'nowrap',  // Prevent line breaks
  
        },
      });
}

export {
    warningToast,
    errorToast,
    infoToast,
    successToast
}