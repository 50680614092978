
const questions = [
    {
      question:
        <pre>
          {
            `
  Q.1 Guess output              
  const promise1 = Promise.resolve('Resolved!');
  promise1.then((result) => console.log(result));
                `
          }
        </pre>
  
  
  
      ,
      options: [
        { text: "run time error", isCorrect: false },
        { text: "Resolved!", isCorrect: true },
        { text: "undefined", isCorrect: false },
        { text: "None of these", isCorrect: false },
      ],
    },
    {
      question:
        <pre>
          {
            `
  Q.2 Guess output.              
  const promise1 =new Promise((resolve, reject)=>{
    reject("success")
  });
  promise1
  .then((result) => console.log(result))
  .catch(error=>console.log("error",error))
  ;
                `
          }
        </pre>
  
  
  
      ,
      options: [
        { text: "error success", isCorrect: true },
        { text: "success", isCorrect: false },
        { text: "undefined", isCorrect: false },
        { text: "None of these", isCorrect: false },
      ],
    },
    {
      question:
      <pre>
      {
        `
  Q.3 Guess output.              
  const promise1 =new Promise((resolve, reject)=>{
  resolve("success")  
  reject("error")
  });
  promise1
  .then((result) => console.log(result))
  .catch(error=>console.log(error))
  ;
            `
      }
    </pre>
  
  
  
      ,
      options: [
        { text: "success error", isCorrect: false },
        { text: "run time error", isCorrect: false },
        { text: "success", isCorrect: true },
  
        { text: "success undefined", isCorrect: false },
      ],
    },
    {
      question:
      <pre>
      {
        `
        Q. 4 Guess output.
        const promise = new Promise
        (res => res(2));
        promise.then(v => {
                console.log(v);
                return v * 2;
            })
            .then(v => {
                console.log(v);
                return v * 2;
            })
            .finally(v => {
                console.log(v);
                return v * 2;
            })
            .then(v => {
                console.log(v);
            });
        
            `
      }
    </pre>
  
  
  
      ,
      options: [
        { text: "run time error", isCorrect: false },
        { text: "2,4,8,16", isCorrect: false },
        { text: "2,4,undefined,8", isCorrect: true },
        { text: "None of these", isCorrect: false },
      ],
    },
    {
      question:
        <pre>
          {
            `
  Q. 5 Guess output          
  const promise11 = Promise.resolve('Resolved');
  promise11.finally((res) => console.log(res));
  
                `
          }
        </pre>
  
  
  
      ,
      options: [
        { text: "run time error", isCorrect: false },
        { text: "Resolved", isCorrect: false },
        { text: "undefined", isCorrect: true },
        { text: "none of these", isCorrect: false },
      ],
    },
    {
      question:
        <pre>
          {
            `
  Q. 6 Guess Output
  console.log("a")          
  const promise1 = Promise.resolve((res,rej)=>{
    console.log("b")
  });
  console.log("c")
                `
          }
        </pre>
  
  
  
      ,
      options: [
        { text: "a,b,c", isCorrect: true },
        { text: "a,c,b", isCorrect: false },
        { text: "run time error", isCorrect: false },
        { text: "none of these", isCorrect: false },
      ],
    },
    {
      question:
        <pre>
          {
            `
  Q. 7 Guess Output
  const promise = new Promise((res,rej)=>{
              rej("hello")
          })
            .catch(error => console.log("error",error))
            .then(res => console.log(res))
                `
          }
        </pre>
  
  
  
      ,
      options: [
        { text: "error hello", isCorrect: false },
        { text: "error hello, undefined", isCorrect: true },
        { text: "run time error", isCorrect: false },
        { text: "none of these", isCorrect: false },
      ],
    },
    {
      question:
        <pre>
          {
            `
  Q.8 Guess Output
  const promise = new Promise(
              (res,rej)=>{
              res("hello")
              })
      .catch(error => console.log("error",error))
      .then(res => console.log(res))
          
                `
          }
        </pre>
  
  
  
      ,
      options: [
        { text: "run time error", isCorrect: false },
        { text: "hello", isCorrect: true },
        { text: "error undefined", isCorrect: false },
        { text: "none of these", isCorrect: false },
      ],
    },
    {
      question:
        <pre>
          {
            `
  Q.9 Guess output          
  const promise = new Promise((resolve, reject) => {
    reject(Error('Some error occurred'));
  })
  promise.catch(error => console.log(error.message));
  promise.catch(error => console.log(error.message));
                `
          }
        </pre>
  
  
  
      ,
      options: [
        { text: "Some error occurred, Some error occurred", isCorrect: true },
        { text: "run time error", isCorrect: false },
        { text: "Some error occurred, undefined", isCorrect: false },
        { text: "compile", isCorrect: false },
      ],
    },
    {
      question:
        <pre>
          {
            `
  Q.10 Guess Output       
  console.log("a")   
  const promise1 = new Promise((res,rej)=>{
  rej("error")
  console.log("b")
  });
  promise1.then(result => console.log(result)).catch(err=>{
      console.log(err)
  })
  console.log("c")
                `
          }
        </pre>
  
  
  
      ,
      options: [
        { text: "a,c,b,error", isCorrect: false },
        { text: "a,c,error,b", isCorrect: false },
        { text: "a,b,c,error", isCorrect: true },
  
        { text: "none of this", isCorrect: false },
      ],
    },
  
  
  ];
  export { questions };
  
  
  
  
  