const ConversionQuestions = [
    {
      question: (
        <pre>
          {`
              
                let string=""
                if(string){
                    console.log("if")
                }else{
                    console.log("else")
                }

                  `}
        </pre>
      ),
      answer: "else",
      question_type: "Think Output🤔 ",
    },
    {
      question: (
        <pre>
          {`
              let arr=[]
              if(arr){
                  console.log("if")
              }else{
                  console.log("else")
              }
              `}
        </pre>
      ),
      answer: "if",
      question_type: "Think Output🤔 ",
    },
    {
      question: (
        <pre>
          {`
           
           function myName(){
            console.log("Hello")
        }
        console.log(myName())
        
          
       
              `}
        </pre>
      ),
      answer: "Hello undefined",
      question_type: "Think Output🤔 ",
    },
  
    
  ];
  export { ConversionQuestions };
  