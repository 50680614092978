import React from 'react';
import { GoogleLogin } from 'react-google-login';

const GoodleLogin = (props) => {


  return (
    <div>
      <GoogleLogin
        clientId={props.clientId}
        buttonText={props.buttonText}
        onSuccess={props.onSuccess}
        onFailure={props.onFailure}
        cookiePolicy={props.cookiePolicy}
        isSignedIn={props.isSignedIn}
    
        // You can add additional parameters as needed, like scope, prompt, etc.
      />
    </div>
  );
};

export default GoodleLogin;