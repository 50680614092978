const HOME_PAGE="HOME_PAGE"
const NINTH_PAGE="NINTH_PAGE"
const TENTH_PAGE="TENTH_PAGE"
const ELEVENTH_PAGE="ELEVENTH_PAGE"
const TWELTH_PAGE="TWELTH_PAGE"
const ADD_DETAIL="ADD_DETAIL"
const ADD_DETAIL_TO_MAINPAGE="ADD_DETAIL_TO_MAINPAGE"
const QUIZ_PAGE="QUIZ_PAGE"
const JAVASCRIPT_TEST="JAVASCRIPT_TEST"
const SHOW_CIRCULAR_LOADER="SHOW_CIRCULAR_LOADER"
const HIDE_CIRCULAR_LOADER="HIDE_CIRCULAR_LOADER"
const INTERVIEW_QUESTION_OUTPUTBASED="INTERVIEW_QUESTION_OUTPUTBASED"
const IS_LOGGED_IN = "IS_LOGGED_IN"






export {
    HOME_PAGE,
    NINTH_PAGE,
    TENTH_PAGE,
    ELEVENTH_PAGE,
    TWELTH_PAGE,
    ADD_DETAIL,
    ADD_DETAIL_TO_MAINPAGE,
    QUIZ_PAGE,
    JAVASCRIPT_TEST,
    SHOW_CIRCULAR_LOADER,
    HIDE_CIRCULAR_LOADER,
    INTERVIEW_QUESTION_OUTPUTBASED,
    IS_LOGGED_IN
}