import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import GoodleLogin from "../login/google-login";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Logo } from "../../docs/index";
import { loginWithGoogleApi } from "../../api";
import Avatar from "@mui/material/Avatar";
import CryptoJS from 'crypto-js';

import { gapi } from "gapi-script";
import {
  goToEleventhPage,
  goToHomePage,
  goToInterviewQuestionOutputBased,
  goToJavaScriptTest,
  goToNinthPage,
  goToQuizPage,
  goToTenthPage,
  goToTwelfthPage,
  isLoggedIn,
  hideCircularLoaderAction,
  showCircularLoaderAction,

} from "../../redux/action-creater";
import CircularColorLoader from "../../component/loader"

import "./Header.scss"; // Assuming you create a CSS file for styling
import Logout from "../login/google-logout";
const clientId=process.env.REACT_APP_PROD_CLIENT_ID
// const clientId =
//   "801950149193-meo862f4k52rglir0siiufh5ptfad79v.apps.googleusercontent.com";
//   const localClientId="662751801883-1eqns1coubaire2luueqtvvgcvaki4ev.apps.googleusercontent.com"

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isSignIn, setIsSignIn] = useState(false);
  const [open, setOpen] = useState(false);
  const history = useNavigate();
  const dispatch = useDispatch();
  const navigate =  useNavigate();
  const {
   
    circularLoader,
  } = useSelector((state) => state.handlePage);

  const responseGoogle = (response) => {
    console.log("response", CryptoJS.AES.encrypt(response.googleId, process.env.REACT_APP_SECRETKEY).toString());
    let body = {
      
      // googleId: response.googleId,
      
      googleId: CryptoJS.AES.encrypt(response.googleId, process.env.REACT_APP_SECRETKEY).toString(),

      name: response.wt.Ad,
      email: response.wt.cu,
      profileImageUrl: response.wt.hK,
    };
    dispatch(showCircularLoaderAction());
    const apiResponse = loginWithGoogleApi(body);

    apiResponse
      .then((res) => {
        console.log("res");
        if (res.data.statusCode === 200) {
          dispatch(hideCircularLoaderAction());

          localStorage.setItem("userInfo", JSON.stringify(body));
          dispatch(isLoggedIn(true));
          setIsSignIn(true);
        } else {
          setIsSignIn(false);
        }
      })
      .catch((error) => {
        dispatch(hideCircularLoaderAction());

        console.log("error", error);
        setIsSignIn(false);
      });

    // console.log("response",response); // Handle the response from Google login
    // You can send the 'response' object to your backend for further authentication and user handling
  };

  const onFailureGoogle = (error) => {
    console.log("error", error);
  };

  // Function to handle navigation to a specific route
  const goToRoute = (routePath) => {
    history(routePath); // Use history.push to navigate to the specified route
  };
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const onLogoutSuccess = (response) => {
    // localStorage.removeItem("userInfo");
    // dispatch(isLoggedIn(false));
    setOpen(true);
    // setIsSignIn(false);
    // console.log("logout success response", response);
  };
  const onLogoutFail = () => {
    console.log("logouot fail");
  };
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
  });

  const handleCloseWithNode = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    localStorage.removeItem("userInfo");
    dispatch(isLoggedIn(false));
    setIsSignIn(false);
    navigate('/')

  };

  return (
    <>
    {
      circularLoader?<CircularColorLoader />:
      <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are You Sure You want to Logout ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Yes</Button>
          <Button onClick={handleCloseWithNode}>No</Button>

          {/* <Button onClick={handleClose}>Yes</Button> */}
        </DialogActions>
      </Dialog>
      <header>
        <div className="headerContainer">
          {/* <h1>Teacher Trek</h1> */}
          <img className="myLogo" src={Logo} />

          <div>
            {localStorage.getItem("userInfo") ? (
              <>
                <div className="header-right">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "10px",
                    }}
                  >
                    <Avatar
                      style={{ marginRight: "10px" }}
                      alt="Travis Howard"
                      src={
                        JSON.parse(localStorage.getItem("userInfo"))
                          .profileImageUrl
                      }
                    />
                    <span style={{ color: "black" }}>
                      {JSON.parse(localStorage.getItem("userInfo")).name}
                    </span>
                  </div>

                  <div
                    className={`menu-icon ${menuOpen ? "open" : ""}`}
                    onClick={toggleMenu}
                  >
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                  </div>
                  <Logout
                  className="google_logout"
                    clientId={
                      clientId
                    }
                    buttonText="Logout"
                    onLogoutSuccess={onLogoutSuccess}
                    onFailure={onLogoutFail}
                  />
                </div>
              </>
            ) : (
              <>
                <GoodleLogin
                className="google_login"
                  clientId={clientId}
                  buttonText="Login with Google"
                  onSuccess={responseGoogle}
                  onFailure={onFailureGoogle}
                  cookiePolicy={"single_host_origin"}
                  isSignedIn={true}
                />
              </>
            )}
          </div>

          {/* <div>Your Gmail</div>
                <div>Log Out</div>
                <div>Sign In</div>
                <div>Sign Out</div> */}
        </div>
        <nav className={`menu ${menuOpen ? "open" : ""}`}>
          <ul>
            <li
              className="menu-close"
              onClick={() => {
                // goToRoute('/')
                setMenuOpen(!menuOpen);
                // dispatch(goToHomePage())
              }}
            >
              <button>&times;</button>
            </li>
            <li
              onClick={() => {
                goToRoute("/");
                setMenuOpen(!menuOpen);
                dispatch(goToHomePage());
              }}
            >
              <button>Home</button>
            </li>
            {/* <li
              onClick={() => {
                goToRoute("/ninth");
                setMenuOpen(!menuOpen);
                dispatch(goToNinthPage());
              }}
            >
              <button>Ninth</button>
            </li> */}
            <li
              onClick={() => {
                goToRoute("/js-logic-building");
                setMenuOpen(!menuOpen);
                // dispatch(goToTenthPage());
              }}
            >
              <button>JS Logic Building</button>
            </li>
            <li
              onClick={() => {
                goToRoute("/programming50-for-begginer");
                setMenuOpen(!menuOpen);
                // dispatch(goToTenthPage());
              }}
            >
              <button>JS 65 Code Pre Leetcode</button>
            </li>
            {/* programming50-for-begginer */}
            {/* <li
              onClick={() => {
                goToRoute("/js-youtube-tutes");
                setMenuOpen(!menuOpen);
                // dispatch(goToTenthPage());
              }}
            >
              <button>JS YouTube Tutorial</button>
            </li> */}
           {/*  <li
              onClick={() => {
                goToRoute("/data-structuring-algorithms");
                setMenuOpen(!menuOpen);
                // dispatch(goToTenthPage());
              }}
            >
              <button>Data Structuring & Algorithms</button>
            </li> */}
            {/* <li
              onClick={() => {
                goToRoute("/dsa-blind");
                setMenuOpen(!menuOpen);
                // dispatch(goToTenthPage());
              }}
            >
              <button>JS Blind 75 Leetcode</button>
            </li> */}
           {/*  <li
              onClick={() => {
                goToRoute("/js-compiler");
                setMenuOpen(!menuOpen);
                // dispatch(goToTenthPage());
              }}
            > */}
             {/*  <button>JS Compiler</button>
            </li> */}
            {/* <li
              onClick={() => {
                goToRoute("/eleventh");
                setMenuOpen(!menuOpen);
                dispatch(goToEleventhPage());
              }}
            >
              <button>Eleventh</button>
            </li> */}
            {/* <li
              onClick={() => {
                goToRoute("/dsa-js");
                setMenuOpen(!menuOpen);
                dispatch(goToTwelfthPage());
              }}
            >
              <button>DSA with JS</button>
            </li> */}
            {/* <li onClick={() =>{ 
                        goToRoute('/math-test')
                        setMenuOpen(!menuOpen)
                        dispatch(goToQuizPage())
                    }
                    }><button>Math Test - (Comming soon)</button></li>  */}
            {/* <li onClick={() =>{ 
                        
                        setMenuOpen(!menuOpen)
                        
                    }
                    }><button >Java Test - (Comming soon)</button></li>   */}

            {/* <li
              onClick={() => {
                goToRoute("/javascript-test");
                setMenuOpen(!menuOpen);
                dispatch(goToJavaScriptTest());
              }}
            >
              <button>JavaScript Test Promise 101</button>
            </li> */}
            {/* <li onClick={() =>{ 
                        goToRoute('/javascript-interview-questions-outputbased')
                        setMenuOpen(!menuOpen)
                        // dispatch(goToInterviewQuestionOutputBased())
                    }
                    }><button 
                    
                     >Interview Question
                     </button></li>   */}
            {/* <li onClick={() =>{ 
                        
                        setMenuOpen(!menuOpen)
                        
                    }
                    }><button >ReactJs Test</button></li> 
                     <li onClick={() =>{ 
                        
                        setMenuOpen(!menuOpen)
                        
                    }
                    }><button >ReduxJs Test</button></li>  */}
            {/* <li onClick={() =>{ 
                        
                        setMenuOpen(!menuOpen)
                        
                    }
                    }><button >SQL Test</button></li>  
                    <li onClick={() =>{ 
                        
                        setMenuOpen(!menuOpen)
                        
                    }
                    }><button >MongoDB Test </button></li>   */}
          </ul>
        </nav>
      </header>
      </>    }
      
    </>
  );
};

export default Header;
