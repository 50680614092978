import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { HeroBg, Hero } from '../../docs';
import { addDetails } from '../../redux/action-creater';
import "./showcase.scss"
import SignUp from '../login/SignUp';
const ShowCase = () => {

  const [isPopupOpen, setPopupOpen] = useState(false);
  const dispatch = useDispatch()
  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };
  return (
    <>





      <div className="main-start-content">

        <div className="main-start-content-child">
          <h3 className='welcome-text'>Welcome to <span className='tt-text'>Teacher Trek 🧑‍🏫</span> </h3>
          <h2>Free Education Platform</h2>
          <p>Mastering JavaScript, <strong> Learn And Grow 🌱</strong> </p>
          <h2>Login for 92 logic building questions & 65 Pre Leetcode Coding Problem.</h2>
          
         
         
          


        </div>
      </div>




    </>
  );
};

export default ShowCase;
