import * as React from 'react';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

export default function CircularColorLoader() {
  return (
    <Stack sx={{
        height: '100vh', // 100% of viewport height
        width: '100%',  // 100% of viewport width
        justifyContent: 'center',
        alignItems: 'center',
        color: 'grey.500',
      }} spacing={2} direction="row">
      <CircularProgress color="secondary" />
      {/* <CircularProgress color="success" />
      <CircularProgress color="inherit" /> */}
    </Stack>
  );
}