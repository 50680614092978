import React from "react";
import { Logo } from "../../docs";
import "./JsImportance.scss"
import { FaNodeJs } from "react-icons/fa";
import { FaFirefoxBrowser } from "react-icons/fa6";
import { SiFramework } from "react-icons/si";
import { FaMobile } from "react-icons/fa";
import { TbDeviceGamepad2 } from "react-icons/tb";
import { IoDesktop } from "react-icons/io5";
import { IoExtensionPuzzle } from "react-icons/io5";
import { IoServer } from "react-icons/io5";
import { MdDashboard } from "react-icons/md";
import { TbScript } from "react-icons/tb";
const JsImportance = () => {
 
  return (
    <>
    
    <div className="js_imp_heading">
        <h2>Before you learn JavaScript, first understand uses area of JavaScript.</h2>
    </div>
      <div className="js_importance_container">
      <div className="js_importance_each">
        <div className="js_importance_img_header">
            <p className="js_imp_icon">
            <FaFirefoxBrowser />
            </p>
    
          <h3>Web Development</h3>
        </div>
        <div className="js_importance_paragraph">
          <p>
            JavaScript is the core scripting language for web browsers, allowing
            developers to create dynamic and interactive websites. It is used
            for enhancing user interfaces, handling user events, and making
            asynchronous requests to servers, among other tasks.
          </p>
        </div>
      </div>
      <div className="js_importance_each">
        <div className="js_importance_img_header">
            <p className="js_imp_icon">
            <SiFramework />
            </p>
          
          <h3>Frontend Frameworks and Libraries</h3>
        </div>
        <div className="js_importance_paragraph">
          <p>
            JavaScript is the foundation for numerous frontend frameworks and
            libraries, such as React, Angular, and Vue.js. These tools
            facilitate the development of complex user interfaces, providing
            modular components, state management, and efficient rendering.
          </p>
        </div>
      </div>
      <div className="js_importance_each">
        <div className="js_importance_img_header">
            <p className="js_imp_icon">
            <FaNodeJs />
            </p>
        
          <h3>Backend Development (Node.js)</h3>
        </div>
        <div className="js_importance_paragraph">
          <p>
            With the advent of Node.js, JavaScript can be used for server-side
            development as well. Developers can use JavaScript to build scalable
            and efficient server applications, making it possible to use the
            same language on both the client and server sides of a web
            application.
          </p>
        </div>
      </div>
      <div className="js_importance_each">
        <div className="js_importance_img_header">
            <p className="js_imp_icon">
            <FaMobile />
            </p>
        
          <h3>Mobile App Development</h3>
        </div>
        <div className="js_importance_paragraph">
          <p>
            JavaScript is widely used in mobile app development, especially with
            frameworks like React Native and frameworks based on Apache Cordova.
            These frameworks enable developers to build cross-platform mobile
            applications using JavaScript, along with HTML and CSS.
          </p>
        </div>
      </div>
      <div className="js_importance_each">
        <div className="js_importance_img_header">
            <p className="js_imp_icon">
            <TbDeviceGamepad2 />
            </p>
       
          <h3>Game Development</h3>
        </div>
        <div className="js_importance_paragraph">
          <p>
            JavaScript, along with HTML5 and CSS, is frequently used for
            browser-based game development. It provides the necessary tools for
            creating interactive and visually appealing games directly within
            web browsers.
          </p>
        </div>
      </div>
      <div className="js_importance_each">
        <div className="js_importance_img_header">
            <p className="js_imp_icon">
            <IoDesktop />
            </p>
         
          <h3>Desktop Application Development</h3>
        </div>
        <div className="js_importance_paragraph">
          <p>
            Through technologies like Electron, developers can use JavaScript to
            build desktop applications for various operating systems, including
            Windows, macOS, and Linux. This allows for the creation of
            cross-platform desktop applications using web technologies.
          </p>
        </div>
      </div>
      <div className="js_importance_each">
        <div className="js_importance_img_header">
            <p className="js_imp_icon">
            <IoServer />
            </p>
        
          <h3>Web Servers and APIs:</h3>
        </div>
        <div className="js_importance_paragraph">
          <p>
            JavaScript is used to build web servers and create RESTful APIs.
            Server-side frameworks such as Express.js make it easier to handle
            HTTP requests, route traffic, and manage databases on the server
            side.
          </p>
        </div>
      </div>
      <div className="js_importance_each">
        <div className="js_importance_img_header">
            <p className="js_imp_icon">
            <IoExtensionPuzzle />
            </p>
          
          <h3>Browser Extensions</h3>
        </div>
        <div className="js_importance_paragraph">
          <p>
            JavaScript is commonly employed in building browser extensions,
            enhancing the functionality of web browsers such as Chrome, Firefox,
            and Edge. It enables developers to create customized features and
            functionalities for users.
          </p>
        </div>
      </div>
      <div className="js_importance_each">
        <div className="js_importance_img_header">
            <p className="js_imp_icon">
            <MdDashboard />
            </p>
          
          <h3>Interactive Dashboards</h3>
        </div>
        <div className="js_importance_paragraph">
          <p>
            JavaScript is essential for building interactive dashboards and data
            visualizations. Libraries like D3.js provide powerful tools for
            creating dynamic charts, graphs, and interactive data displays on
            websites.
          </p>
        </div>
      </div>
      <div className="js_importance_each">
        <div className="js_importance_img_header">
            <p className="js_imp_icon">
            <TbScript />
            </p>
        
          <h3>Automation and Scripting</h3>
        </div>
        <div className="js_importance_paragraph">
          <p>
            JavaScript is used for automation tasks and scripting, often in
            conjunction with tools like Node.js. It can be employed for tasks
            such as web scraping, build automation, and general-purpose
            scripting.
          </p>
        </div>
      </div>
    </div>
    </>
  
  );
};

export default JsImportance;
