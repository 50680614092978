
const PolyfillQuestions = [
   
    {
      question:
        <pre>
          {
            `
            Array.prototype.myMap=function(callback){
                let result=[]
                for(let i=0;i<this.length;i++){
                  result.push(callback(this[i],i,this))
                }
                return result
            }
            const nums=[1,2,3,4]
            
            const multiplyThree=nums.myMap((num,i,arr)=>{
                return num*3
            })
            console.log(multiplyThree)
            

                `
          }
        </pre>,
        answer:"3, 6, 9, 12",
        question_type:"Write polyfill for Map method."
  
    },
    {
      question:
        <pre>
          {
            `
            Array.prototype.myFilter=function(callback){
                let result=[]
                for(let i=0;i<this.length;i++){
                    if(callback(this[i],i,this)) result.push(this[i])
                }
                return result;
            }
            const nums=[1,2,3,4]
            const moreThenTwo=nums.myFilter((num,i,arr)=>{
                return num>2
            })
            console.log(moreThenTwo)
            `
          }
        </pre>,
        answer:"3, 4",
        question_type:"Write polyfill for Filter method."
  
  
  
  
    },
    {
      question:
      <pre>
      {
        `
         
        Array.prototype.myReduce=function(callback,initialValue){
            let accumulator=initialValue
            for(let i=0;i<this.length;i++){
                accumulator=accumulator?callback(accumulator,this[i],i,this[i]):this[i];
        
            }
            return accumulator
        
        
        }
        const nums=[1,2,3,4]
        const sum=nums.myReduce((acc,curr,i,nums)=>{
            return acc+curr;
        },0)
        console.log(sum)
            `
      }
    </pre>,
    answer:"10",
    question_type:"Write polyfill for Reduce method."
  
  
  
  
     
    },
    
  ];
  export { PolyfillQuestions };
  
  
  
  
  