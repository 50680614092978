import {
    HOME_PAGE,
    NINTH_PAGE,
    TENTH_PAGE,
    ELEVENTH_PAGE,
    TWELTH_PAGE,
    ADD_DETAIL,
    ADD_DETAIL_TO_MAINPAGE,
    QUIZ_PAGE,
    JAVASCRIPT_TEST,
    SHOW_CIRCULAR_LOADER,
    HIDE_CIRCULAR_LOADER,
    INTERVIEW_QUESTION_OUTPUTBASED,
    IS_LOGGED_IN
}  from "../action-constant/index"
const goToHomePage = () => {
    return {
      type: HOME_PAGE,
    };
  };
  
  const goToNinthPage = () => {
    return {
      type: NINTH_PAGE,
    };
  };
  
  const goToTenthPage = () => {
    return {
      type: TENTH_PAGE,
    };
  };
  
  const goToEleventhPage = () => {
    return {
      type: ELEVENTH_PAGE,
    };
  };
  
  const goToTwelfthPage = () => {
    return {
      type:TWELTH_PAGE,

    };
  };
  const goToQuizPage = () => {
    return {
      type:QUIZ_PAGE,

    };
  };
  const goToJavaScriptTest=()=>{
    return {
      type:JAVASCRIPT_TEST,

    };
  }
  const goToInterviewQuestionOutputBased=()=>{
    return {
      type:INTERVIEW_QUESTION_OUTPUTBASED,

    };
  }
  const addDetails = () => {
    return {
      type:ADD_DETAIL,

    };
  };
  const addDetailsToMainPage = () => {
    return {
      type:ADD_DETAIL_TO_MAINPAGE,

    };
  };
  const showCircularLoaderAction = () => {
    return {
      type:SHOW_CIRCULAR_LOADER,

    };
  };
  const hideCircularLoaderAction = () => {
    return {
      type:HIDE_CIRCULAR_LOADER,

    };
  };

  const isLoggedIn = (data) => {
    console.log("action creator ", data)
    return {
      type:IS_LOGGED_IN,
      payLoad: data

    };
  };

  export {
    goToEleventhPage,
    goToHomePage,
    goToNinthPage,
    goToTenthPage,
    goToTwelfthPage,
    addDetails,
    addDetailsToMainPage,
    goToQuizPage,
    goToJavaScriptTest,
    showCircularLoaderAction,
    hideCircularLoaderAction,
    goToInterviewQuestionOutputBased,
    isLoggedIn
  }