const HoistingQuestions = [

  {
    question: (
      <pre>
        {`
           
            console.log(x);
            var name = "Prakash;
            

                `}
      </pre>
    ),
    answer: " undefined",
    question_type: "Think Output🤔 ",
  },
  {
    question: (
      <pre>
        {`

console.log(z); 
let z = 20;

                `}
      </pre>
    ),
    answer: "ReferenceError: Cannot access 'z' before initialization",
    question_type: "Think Output🤔 ",
  },
  {
    question: (
      <pre>
        {`
var age=18
function printMyAge(){
    console.log(age)
    var age=20
    console.log(age)
}
printMyAge()
          
           `}
      </pre>
    ),
    answer: "undefined, 20",
    question_type: "Think Output🤔 ",
  },
  {
    question: (
      <pre>
        {`
         
         var age=18
         function printMyAge(){
             console.log(age)
             let age=20
             console.log(age)
         }
         printMyAge()
            `}
      </pre>
    ),
    answer: "ReferenceError: Cannot access 'age' before initialization",
    question_type: "Think Output🤔 ",
  },
  {
    question: (
      <pre>
        {`
         
         var age=23
         function printMyAge(){
             console.log(age)
             var age=22
             console.log(age)
             if(true){
                 console.log(age)
                 let age=21
             }
         }
         printMyAge()
            `}
      </pre>
    ),
    answer: "undefined 22 ReferenceError: Cannot access 'age' before initialization",
    question_type: "Think Output🤔 ",
  },
  {
    question: (
      <pre>
        {`
 
 foo(); 
 function foo() {
     console.log("Hello");
 }
 
            `}
      </pre>
    ),
    answer: "20",
    question_type: "Think Output🤔 ",
  },
  {
    question: (
      <pre>
        {`
  
  bar(); 
  var bar = function() {
      console.log("World");
  };
  
  
                `}
      </pre>
    ),
    answer: "TypeError: bar is not a function",
    question_type: "Think Output🤔 ",
  },
  
 
];
export { HoistingQuestions };
