import { GoogleLogout } from "react-google-login";
const clientId="801950149193-meo862f4k52rglir0siiufh5ptfad79v.apps.googleusercontent.com"
function Logout(props){
    
    return(
        <>
        <GoogleLogout
        clientId={props.clientId}
        buttonText={props.buttonText}
        onLogoutSuccess={props.onLogoutSuccess}
        onFailure={props.onFailure}
         />
        </>
    )
}
export default Logout