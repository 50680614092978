import React, { useState } from "react";
import Select from "react-select";
// import { customStyles } from "../constants/customStyles";
import { languageOptions } from "./constants/languageOptions";

const LanguagesDropdown = ({ onSelectChange }) => {
    const [language, setLanguage]=useState("")
   /*  const onSelectChange = (sl) => {
        setLanguage(sl);
    }; */
  return (
    <Select
      placeholder={`Filter By Category`}
      options={languageOptions}
    //   styles={customStyles}
      defaultValue={languageOptions[0]}
      onChange={(selectedOption) => onSelectChange(selectedOption)}
    />
  );
};

export default LanguagesDropdown;