const DataTypeQuestions = [
    {
      question: (
        <pre>
          {`
              
              let num1=5
let num2=new Number(7)
console.log(typeof num1,typeof  num2)
                  `}
        </pre>
      ),
      answer: "number object",
      question_type: "Think Output🤔 ",
    },
    {
      question: (
        <pre>
          {`
              let num1=0
              let num2=new Number(0)
              if(num1)
              console.log("hi")
              
              if(num2)
              console.log("Hello")
              `}
        </pre>
      ),
      answer: "Hello",
      question_type: "Think Output🤔 ",
    },
    {
      question: (
        <pre>
          {`
           
           console.log(isNaN(NaN))
console.log(isNaN("Prakash"))
console.log(NaN===NaN)
              `}
        </pre>
      ),
      answer: "true, true, false",
      question_type: "Think Output🤔 ",
    },
    {
      question: (
        <pre>
          {`
        const name="Prakash"
        name[2]="h"
        console.log(name)
              `}
        </pre>
      ),
      answer: "Prakash",
      question_type: "Think Output🤔 ",
    },
 
    
  ];
  export { DataTypeQuestions };
  