import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
const routePaths = [
  "/ninth",
  "/math-test",
  "/tenth",
  "/eleventh",
  "/twelfth",
  "/javascript-test",
  "/javascript-interview-questions-outputbased",
];
const NotFound = () => {
  const [validatePath, setValidatePath] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location, "location");

  useEffect(() => {
    if (routePaths.includes(location.pathname)) {
      setValidatePath(true);
    }
    let timer = setTimeout(() => {
      navigate("/");
    }, 2500);
    return () => {
      console.log("cpmponent unmounted");
      clearTimeout(timer);
    };
  }, []);
  return (
    <>
      {validatePath ? (
        <div>
          <h2>Login to access this Page!</h2>
          <p>Redirecting you to Home Page...</p>
        </div>
      ) : (
        <div>
          <h2>404 - Page Not Found</h2>
          <p>Redirecting you to Home Page...</p>
        </div>
      )}
    </>
  );
};

export default NotFound;
