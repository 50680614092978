import React from 'react'
import { ToastContainer, toast } from 'react-toastify';
// import "./Home.scss"
import {
  L1Nine,
  L2Nine,
  L3Nine,
  L4Nine
} from "../../docs/index"
import Header from '../../component/header';

const Ninth = () => {

  const handleDownloadToastMessage=()=>{
    toast.success('Download Successful !', {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 200,
      closeButton:false,
      style: {
        background: '#4CAF50', // Background color
        color: '#fff',         // Text color
        borderRadius: '8px',   // Border radius
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Box shadow
        fontSize: '12px',      // Font size
        whiteSpace: 'nowrap',  // Prevent line breaks

      },
    });
  }
  return (
    <>
    <Header />
    <div className='numbeOfLectoreHome'>
      <h2>Class 9 Math All Lecture</h2>

    </div>
    <div className="homecontainer">
    <div className="homecard">
           <h2>Class 9th Math : L4</h2>
           <p>Polynomial - Chapter 2</p>
           <p>Topic: Factorisation of polynomial. Briefly explained. Theory and problem solving. Exercise 2.3 discussed.</p>
           <a onClick={handleDownloadToastMessage} href={L4Nine} download="">Download Notes</a>
           <a href="https://www.youtube.com/watch?v=vjBFri9ogkM" target="_blank">Watch Video</a>
       </div>
       
       <div className="homecard">
           <h2>Class 9th Math : L3</h2>
           <p>Polynomial - Chapter 2</p>
           <p>Topic: Zeroes of a Polynomial. Briefly explained. Theory and problem solving. Exercise 2.2 discussed.</p>
           <a onClick={handleDownloadToastMessage} href={L3Nine} download="">Download Notes</a>
           <a href="https://www.youtube.com/watch?v=mbGYLnI1ke0" target="_blank">Watch Video</a>
       </div>
       <div className="homecard">
           <h2>Class 9th Math : L2</h2>
           <p>Polynomial - Chapter 2</p>
           <p>Topic: Polynomial in one variable. Briefly explained. Theory and problem solving. Exercise 2.1 discussed.
           </p>
           <a onClick={handleDownloadToastMessage} href={L2Nine} download="">Download Notes</a>
           <a href="https://www.youtube.com/watch?v=ZY7Q3c_diJ4" target="_blank">Watch Video</a>
       </div>
       <div className="homecard">
           <h2>Class 9th Math : L1</h2>
           <p>Introduction/basic</p>
           <p>It was just an introduction. Many more to come.</p>
           <a onClick={handleDownloadToastMessage} href={L1Nine} download="">Download Notes</a>
           <a href="https://www.youtube.com/watch?v=XhbRpTrXTis&t=561s" target='_blank'>Watch Video</a>
       </div>
     
       
       
   </div>
    </>
   
  )
}

export default Ninth