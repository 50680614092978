import React,{useState} from "react";
import { ToastContainer, toast } from "react-toastify";
import "./style.scss"
import { ClosureQuestions } from "./ClosureQuestions";
// import { Clo } from "./PromiseQuestion";
// import "./Home.scss"
// import {
//   L1Nine,
//   L2Nine,
//   L3Nine,
//   L4Nine
// } from "../../docs/index"
// import Header from "../../component/header";

const ClosureJaavascriptTutes = () => {
  const numberOfQuestion=ClosureQuestions.length;
  let explanationVisibilityData={}
  let copyQuestionIndividual={}
  for (let index = 0; index < numberOfQuestion; index++) {
    explanationVisibilityData[index+1]=false
    copyQuestionIndividual[index+1]=false
  }
  /* const [explanationVisibility, setExplanationVisibility] = useState({
    1: false,
    2: false,
    3: false,
    // Add more keys for each question
  }); */
  const [explanationVisibility, setExplanationVisibility] = useState(explanationVisibilityData);

  const toggleExplanation = (questionNumber) => {
    setExplanationVisibility((prevVisibility) => ({
      ...prevVisibility,
      [questionNumber]: !prevVisibility[questionNumber],
    }));
  };
  const [copiedText, setCopiedText] = useState('');
  const [copyQuestion, setCopyQuestion] = useState(copyQuestionIndividual);

  const copyToClipboard = (index,questionNumber) => {
    // const textToCopy = typeof text === 'object' ? JSON.stringify(text)?.props?.children : text;
    // navigator.clipboard.writeText(textToCopy);
  const innerText=  document.getElementsByClassName("questions_ui_closure")[index].innerText
     navigator.clipboard.writeText(innerText);

    setCopiedText('copied!');
    /* setTimeout(()=>{
      setCopiedText('copy');
    },2000) */
    setCopyQuestion((prevVisibility) => ({
      ...prevVisibility,
      [questionNumber]: !prevVisibility[questionNumber],
    }));
  };

  return (
    <div className="logic_building_question_container">
      <h3>Total Question : {ClosureQuestions.length} </h3>
      <ol>
        {/* <li>
          <strong>Output of the following code:</strong>
          <pre>{`console.log(a);\nvar a = 5;`}</pre>
          <button onClick={() => toggleExplanation(1)}>
            {explanationVisibility[1] ? 'Hide Explanation' : 'Show Explanation'}
          </button>
          {explanationVisibility[1] && (
            <>
              <strong>Expected Output:</strong> `undefined`
              <p>
                <strong>Explanation:</strong> Variable `a` is hoisted to the top
                of the scope and initialized with `undefined` before the
                `console.log` statement.
              </p>
            </>
          )}
        </li> */}
        {
          ClosureQuestions.map((q,index)=>{
            return(
              <>
              <li>
              <span>Q. {index+1} {q.question_type}</span> 
                <button className="copyQuestionButton" onClick={() => copyToClipboard(index,index+1)}>
            {copyQuestion[index+1] && copiedText ? copiedText : 'copy'}
          </button>
              <p className="questions_ui_closure">{q.question}</p>
              <button onClick={() => toggleExplanation(index+1)}>
            {explanationVisibility[index+1] ? 'Hide Answer' : 'See Answer'}
          </button>
          {explanationVisibility[index+1] && (
            <>
              <strong style={{marginLeft:'20px'}}>Expected Output : {q.answer}</strong>
             
           
            </>
          )}
              </li>
              
              </>
            )
          })
        }

      </ol>
    </div>
  );
};

export default ClosureJaavascriptTutes;
