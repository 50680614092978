
const ClosureQuestions = [
   
    {
      question:
        <pre>
          {
            `
           
            function outerFunction() {
                var outerVariable = "Hello, ";
                function innerFunction(name) {
                    console.log(outerVariable + name);
                }
                return innerFunction;
            }
            var inner = outerFunction();
            inner("Prakash");

                `
          }
        </pre>,
        answer:"Hello, Prakash",
        question_type: "Think Output🤔 ",
  
    },
    {
      question:
        <pre>
          {
            `
          
            function createCounter() {
                var count = 0;
                function counter() {
                    count++;
                    console.log(count);
                }
                return counter;
            }
                
                var increment = createCounter();
                increment(); 
                increment(); 
                increment();
            `
          }
        </pre>,
        answer:"1, 2, 3",
        question_type: "Think Output🤔 ",
  
  
  
    },
    {
      question:
      <pre>
      {
        `
         
        function outer() {
            var x = 10;
            function inner() {
                console.log(x);
            }
            return inner;
        }
            
        var innerFunc = outer();
        innerFunc();
            `
      }
    </pre>,
    answer:"10",
    question_type: "Think Output🤔 ",
  
  
  
     
    },
    {
      question:
      <pre>
      {
        `
 
        function outer() {
            var x = 10;           
            function inner() {
                console.log(x);
            }
            x = 20;
            return inner;
        }

        var innerFunc = outer();
        innerFunc();
            `
      }
    </pre>,
    answer:"20",
    question_type: "Think Output🤔 ",
  
  
  
    },
    {
      question:
        <pre>
          {
            `
  
            function outer() {

                var x = 10;

                function inner() {
                
                    var y = 5;
                    console.log(x + y);
                
                }
                
                return inner;
                
                }
                
                var innerFunc = outer();
                innerFunc();
  
                `
          }
        </pre>,
        answer:"15",
        question_type: "Think Output🤔 ",
  
  
  
      
    },
    {
      question:
        <pre>
          {
            `

            function outer() {

                var x = 10;
                
                function inner() {
                
                var y = 5;
                
                console.log(x + y);
                
                }
                
                var x = 20;
                
                return inner;
                
                }
                
                var innerFunc = outer();
                
                innerFunc();
                `
          }
        </pre>,
        answer:"25",
        question_type: "Think Output🤔 ",
  
  
  
      
    },
    {
      question:
        <pre>
          {
            `
            function outer() {

                var x = 10;
                
                function inner() {
                
                var y = 5;
                
                console.log(x + y);
                
                x = 20;
                
                }
                
                return inner;
                
                }
                
                var innerFunc = outer();
                
                innerFunc();
                
                innerFunc();
                `
          }
        </pre>,
        answer:"15,25",
        question_type: "Think Output🤔 ",
  
  
  
      
    },
    
  
  
  ];
  export { ClosureQuestions };
  
  
  
  
  