
const ObjectQuestions = [
    {
      question:
        <pre>
          {
            `
            let user1={
                name:"Prakash",
                age:25
            }
            let user2=user1
            user1.age=20
            console.log(user1)
            console.log(user2)
            `
          }
        </pre>,
        answer:"20, 20!",
        question_type: "Think Output🤔 ",
  
    },
    {
      question:
        <pre>
          {
            `
          
            let age="age"
            const user={
                [age]:22
            }
            console.log(user.age)
                `
          }
        </pre>,
        answer:"22",
        question_type: "Think Output🤔 ",
  
  
  
    },
    {
      question:
      <pre>
      {
        `
         
        let age="age"
        const user={
            [age]:22,
            name:"Prakash"
        }
        console.log("name" in user)
            `
      }
    </pre>,
    answer:"true",
    question_type: "Think Output🤔 ",   
    },
    {
      question:
      <pre>
      {
        `
         
        let user = {
          name:"Prakash",
          age:22,
          college:"abc",
          marks:12,
          ["full name"]:"Prakash Kumar",
          address:{
           city:"Sonpur",
           state:"Bihar"
          }
       }
       console.log(user["name"])
       console.log(user["full name"])
       console.log(user["address"]['city'])
            `
      }
    </pre>,
    answer:"Prakash, Prakash Kumar, Sonpur",
    question_type: "Think Output🤔 ",   
    },
    {
      question:
      <pre>
      {
        `
 
        let age="age"
        const user={
            [age]:22,
            name:"Prakash",
            marks:undefined
        }
        console.log("marks" in user)
        
            `
      }
    </pre>,
    answer:"true",
    question_type: "Think Output🤔 ",
  
  
  
    },
    {
      question:
      <pre>
      {
        `
 
        let user = {
          name:"Prakash",
          age:22,
          address:{
          city:"Sonpur"
         }
       }
      
       console.log(user?.address?.state?.some)
       console.log(user.address.state.some)
        
            `
      }
    </pre>,
    answer:"undefined, TypeError: Cannot read properties of undefined (reading 'some')",
    question_type: "Think Output🤔 ",
  
  
  
    },
   
  
  
  ];
  export { ObjectQuestions };
  
  
  
  
  