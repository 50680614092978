// const CompanyName = [
//     'Tata Consultancy Services (TCS)',
//     'Infosys',
//     'Wipro',
//     'HCL Technologies',
//     'Tech Mahindra',
//     'Capgemini',
//     'Cognizant',
//     'Accenture',
//     'IBM India',
//     'Microsoft',
//     'Oracle',
//     'Adobe',
//     'Cisco',
//     'Intel India',
//     'NVIDIA',
//     'Qualcomm',
//     'Paytm',
//     'Flipkart',
//     'Amazon',
//     'Walmart',
//     'MakeMyTrip',
//     'Ola',
//     'Uber',
//     'Swiggy',
//     'Zomato',
//     'BookMyShow',
//     'InMobi',
//     'Freshworks',
//     'Myntra'
//   ];
  const topicsName = [
    'Variable',
   
    
    'Data Types',

    'Function',
    'Object',
    "Destructuring",
    'Conversion',
    'This Keyword',
    // 'Prototype',
    'Call Apply Bind',
    'Polyfill',
    "Error Handling",
    // 'Callback',
    'Promise',
    'Async-Await',
    'Scope',
    'Closure',
    'Hoisting',
    'Event-Loop',
    // 'Events',
    // 'Document Object Model',
    // 'Browser Object Model',
    // "setTimeout and setInterval",
    // "Currying"
  
  
  ];
  
export default topicsName