import React, { useEffect, useState } from "react";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./component/footer/Footer";
import { MySquareLogo } from "./docs";
import Header from "./component/header";
import Home from "./pages/home/index";
import Ninth from "./pages/ninth";
import Tenth from "./pages/tenth";
import Eleventh from "./pages/eleventh";
import Twelveth from "./pages/twelveth";
import RegistrationForm from "./component/login/SignUp";
// import GoogleLogin from "./component/login/google-login";
import { useSelector } from "react-redux";
import ShowCase from "./component/showcase";
import SignUp from "./component/login/SignUp";
import Quiz from "./pages/MathTest";
import LogicBuildingQuestion from "../src/component/LogicBuilding/LogicBuildingQues"
import JavaScriptTest from "./pages/JavaScriptTest";
import { SignedOut, SignedIn } from "@clerk/clerk-react";
import InterviewQuestionOutputbased from "./pages/interviewQuestionOutputbased";
import {
  HashRouter as Router,
  Routes,
  Route,
  useHistory,
} from "react-router-dom";
import NotFound from "./component/NotFound";
import DSA from "./component/DataStructureAndAlgorithm/DSA";
import YouTubeTutorial from "./component/YouTubeTutorial/Youtubetutes";
import JsCompiler from "./pages/JsCompiler/Landing";
import DSAandAlogo from "./pages/datastructuring-algorithms/Tabs";
import Programming50Begineer from "./component/programmingBegginer/Program50";
// import Parent from "./component/JsCompiler/Parent";

function App() {
  const {
    isHome,
    isNinth,
    isTenth,
    isEleventh,
    isTwelth,
    isDetailed,
    isQuiz,
    isJavaScriptTest,
    circularLoader,
    interviewQuestionOutputBased,
  } = useSelector((state) => state.handlePage);
  const isLoggedIn = useSelector((state) => state.handlePage.isLoggedIn);
  console.log(isLoggedIn, "isLoggedIn");
  const [isHomePage, setIsHomePage] = useState(false);
  const check = localStorage.getItem("userInfo");
  // let userInfo=JSON.parse(localStorage.getItem("userInfo");

  return (
    <div className="App">
      {/* { !isQuiz && <Header />} */}
      <Router>
        {/* {!isJavaScriptTest && <Header />} */}

        <Routes>
          <Route path="/" element={<Home />} />
          {check && <Route path="/ninth" element={<Ninth />} />}
          {check && <Route path="/math-test" element={<Quiz />} />}
          {check && <Route path="/js-logic-building" element={< LogicBuildingQuestion/>} />}
          {/* js-compiler */}
          {check && <Route path="/js-youtube-tutes" element={< YouTubeTutorial/>} />}
          {check && <Route path="/js-compiler" element={< JsCompiler/>} />}
          {check && <Route path="/programming50-for-begginer" element={<Programming50Begineer />}/>}

          {check && <Route path="/data-structuring-algorithms" element={<DSAandAlogo />} />}

          {check && <Route path="/tenth" element={<Tenth />} />}
          {check && <Route path="/eleventh" element={<Eleventh />} />}
          {check && <Route path="/twelfth" element={<Twelveth />} />}
          {check && (
            <Route path="/javascript-test" element={<JavaScriptTest />} />
          )}
          {check && (
            <Route
              path="/javascript-interview-questions-outputbased"
              element={<JavaScriptTest />}
            />
          )}
          <Route path="*" element={<NotFound />} />
          {/* Add more routes if needed */}
        </Routes>
      </Router>

      <ToastContainer />
    </div>
  );
}

export default App;
