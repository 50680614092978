export const languageOptions = [
    {
      id: 63,
      name: "JavaScript (Node.js 12.14.0)",
      label: "JavaScript (Node.js 12.14.0)",
      value: "javascript",
    },
   /*  {
      id: 45,
      name: "Assembly (NASM 2.14.02)",
      label: "Assembly (NASM 2.14.02)",
      value: "assembly",
    },
    
    {
      id: 84,
      name: "Visual Basic.Net (vbnc 0.0.0.5943)",
      label: "Visual Basic.Net (vbnc 0.0.0.5943)",
      value: "vbnet",
    }, */
  ];