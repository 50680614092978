const VariableQuestions = [
    {
      question: (
        <pre>
          {`
             var name="Prakash"
             var name="Prakash"
             console.log(name)
                  `}
        </pre>
      ),
      answer: "Prakash",
      question_type: "Think Output🤔 ",
    },
    {
      question: (
        <pre>
          {`
              let age=20
              let age=25
              console.log(age)
              `}
        </pre>
      ),
      answer: "SyntaxError: Identifier 'age' has already been declared",
      question_type: "Think Output🤔 ",
    },
    {
      question: (
        <pre>
          {`
              let $ = 11; 
              let _ = 26;
              
              console.log($ + _);
              `}
        </pre>
      ),
      answer: "37",
      question_type: "Think Output🤔 ",
    },
    {
        question: (
          <pre>
            {`
                let anyDataType=20
                anyDataType="Prkash"
                anyDataType={name:"Prakash",city:"sonpur"}
                console.log(anyDataType)
                `}
          </pre>
        ),
        answer: "{'name': 'Prakash','city': 'sonpur'}",
        question_type: "Think Output🤔 ",
      },
    {
      question: (
        <pre>
          {`
           
           const name="Prakash"
           name="Akash"
           console.log(name)
              `}
        </pre>
      ),
      answer: "TypeError: Assignment to constant variable.",
      question_type: "Think Output🤔 ",
    },
 
    
  ];
  export { VariableQuestions };
  