// Footer.jsx

import React from 'react';
import { AiFillInstagram, AiFillYoutube } from 'react-icons/ai';
import './Footer.scss'; // Assuming you've created a SCSS file for styling

const Footer = () => {
  return (
    <footer className="footer">
      
      <div className="social-icons">
        <a href="https://www.instagram.com/teachertrekofficial/?hl=en" target="_blank" rel="noopener noreferrer">
          <AiFillInstagram className="icon instagram-icon" /> Official
        </a>
        <a href="https://www.instagram.com/x64.prakash.exe/?hl=en" target="_blank" rel="noopener noreferrer">
          <AiFillInstagram className="icon instagram-icon" /> Author
        </a>
        <a href="https://www.youtube.com/@teachertrek/playlists" target="_blank" rel="noopener noreferrer">
          <AiFillYoutube className="icon youtube-icon" /> Youtube
        </a>
        {/* Add more social icons as needed */}
      </div>
      <p>Follow us, don't miss an update!</p>
    </footer>
  );
};

export default Footer;
