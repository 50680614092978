import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import { allLecture } from '../lecture-list';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import "./Home.scss"
import {
  L1Nine,
  L2Nine,
  L3Nine,
  L412th,
  L4Nine,
  MathClass10L1,
  MathClass10L2,
  MathClass10L3,
  MathClass10L4,
  MathClass10L5,
  MathClass10L7,
  MathClass10L9,
  Matrix12thL2,
  Matrix12thL3,
  myImageHome
} from "../../docs/index"
import ShowCase from '../../component/showcase';
import Header from '../../component/header';
import { Button } from '@mui/material';
import Footer from '../../component/footer/Footer';
import BasicTabs from '../../component/tabs/Tabs';
import AllCompany from '../../component/LogicBuilding/LogicBuildingQues';
import JsImportance from '../../component/JsImportance/JsImportance';

const Home = () => {
  const [searchField,setSearchField]=useState("")
  const [lectures, setLectures]=useState(allLecture)
  // let filterLecture = allLecture.filter(lecture => lecture.lectureNumber.includes("10th"))
  // console.log("filterlecture1", filterLecture)
  const handlesearchTopic=(e)=>{
    console.log("e",e.target.value)
    const filterLecture=allLecture.filter(lecture=>lecture.chatpterNumber.toLowerCase().includes(e.target.value))
    setLectures(filterLecture)
    console.log("filterlecture2", filterLecture)

    setSearchField(e.target.value)
  }
  // let allLecture = filterLecture
  const handleDownloadToastMessage = () => {

    toast.success('Download Successful !', {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 200,
      closeButton: false,
      style: {
        background: '#4CAF50', // Background color
        color: '#fff',         // Text color
        borderRadius: '8px',   // Border radius
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Box shadow
        fontSize: '12px',      // Font size
        whiteSpace: 'nowrap',  // Prevent line breaks

      },
    });
  }
  return (
    <>

      <Header />
      {/* <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
        <Alert style={{ width: "100%", textAlign: "center" }} severity="warning">Attend Test And Get Your Marks On Your Mail After Last Date The last date of <strong>JavaScript Promise 101</strong> test is <strong>20 Jan 2024</strong>. </Alert>


      </div> */}

      {/* <div className="homecard">
          <h2>Class 10th Math : L9</h2>
          <p>Arithmetic Progression - Chapter 5</p>
          <p>Topic: Introduction to Arithmetic Progression. Basic of Arithmetic Progression </p>
          <a   download={handleDownloadToastMessage} href={MathClass10L9}>Download Notes</a>
          <a href="https://www.youtube.com/watch?v=ENpn_Jtud7w&list=PLtLpyd3c90MylVPn85Lzymt4iodloAzXw&index=9" target="_blank">Watch Video</a>
        </div> */}


      <ShowCase />
      <JsImportance/>
      {/* <img src={myImageHome} alt='hello'/> */}
      {/* <AllCompany /> */}
      {/* <BasicTabs /> */}
      <Footer />
     
    </>
  )
}

export default Home