
const ThisPrototypePolyfillQuestions = [
    {
      question:
        <pre>
          {
            `
           
            const user={
                firstName:"Prakash",
                lastName:"Kumar",
                printFullName:function(){
                    console.log(this)
                }
            }
            
            user.printFullName() 

                `
          }
        </pre>,
        answer:`user object`,
        question_type: "Think Output🤔 ",
  
    },
    {
      question:
        <pre>
          {
            `
            const user={
                firstName:"Prakash",
                lastName:"Kumar",
                printFullName:function(){
                    console.log(this)
                    function willTryAccessFullname(){
                        console.log(this)
                    }
                    willTryAccessFullname()
                }
            }
            
            user.printFullName()

                `
          }
        </pre>,
        answer:"user object, window object",
        question_type: "Think Output🤔 ",
  
  
  
    },
    {
      question:
      <pre>
      {
        `
         
        const user={
            firstName:"Prakash",
            lastName:"Kumar",
            printFullName:function(){
                console.log(this)
                const willTryCopyFullname=()=>{
                    console.log(this)
                }
                willTryCopyFullname()
            }
        }
        
        user.printFullName()
            `
      }
    </pre>,
    answer:"user object, user object",
    question_type: "Think Output🤔 ",
  
  
  
     
    },
    {
      question:
      <pre>
      {
        `
 
        const user={
            firstName:"Prakash",
            lastName:"Kumar",
            printFullName:()=>{
                console.log(this)
                
            }
        }
        
        user.printFullName()
        
            `
      }
    </pre>,
    answer:"window object",
    question_type: "Think Output🤔 ",
  
  
  
    },
    {
      question:
        <pre>
          {
            `
  
            
const student={
    firstName:"Prakash",
    lastName:"Kumar",
    address:{
        city:"Sonpur",
        state:"Bihar",
        pincode:111111,
        fullAddress:function(){
            console.log(this)
            
        }
    }
}
student.address.fullAddress()
  
                `
          }
        </pre>,
        answer:"address object",
        question_type: "Think Output🤔 ",
  
  
  
      
    },
    {
      question:
        <pre>
          {
            `

            document.getElementById("btn").addEventListener("click",function(){
    
                console.log(this)
                const performAction=()=>{
                    console.log(this)
            
                }
                performAction()
            })
                `
          }
        </pre>,
        answer:"<buttton id='btn'>Click Me</button>,<buttton id='btn'>Click Me</button>",
        question_type: "Think Output🤔 ",
  
  
  
      
    },
    {
      question:
        <pre>
          {
            `

            document.getElementById("btn").addEventListener("click",()=>{
                console.log(this)
            })
                `
          }
        </pre>,
        answer:"window object",
        question_type: "Think Output🤔 ",
  
  
  
      
    },
    {
        question:
          <pre>
            {
              `
  
              document.getElementById("btn").addEventListener("click",function(){
                console.log(this)
                })
                  `
            }
          </pre>,
          answer:"<buttton id='btn'>Click Me</button>",
          question_type: "Think Output🤔 ",
    
    
    
        
      },
    
    {
      question:
        <pre>
          {
            `
            const student={
                firstName:"Prakash",
                lastName:"Kumar",
                address:{
                    city:"Sonpur",
                    state:"Bihar",
                    pincode:111111,
                    fullAddress:function(){
                        console.log(this)
                        function willTryAccessFullAddress(){
                            console.log(this)
                        }
                        willTryAccessFullAddress()
                        
                    }
                }
            }
            student.address.fullAddress()
                `
          }
        </pre>,
        answer:"address object, window object",
        question_type: "Think Output🤔 ",
  
  
  
    
    },
    {
      question:
        <pre>
          {
            `
   
            const student={
                firstName:"Prakash",
                lastName:"Kumar",
                address:{
                    city:"Sonpur",
                    state:"Bihar",
                    pincode:111111,
                    fullAddress:function(){
                        console.log(this)
                        const willTryAccessFullAddress=()=>{
                            console.log(this)
                        }
                        willTryAccessFullAddress()
                        
                    }
                }
            }
            student.address.fullAddress()
                `
          }
        </pre>,
        answer:"address object, address object",
        question_type: "Think Output🤔 ",
  
  
  
      
    },
  
  
  ];
  export { ThisPrototypePolyfillQuestions };
  
  
  
  
  