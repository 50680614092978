import React from "react";
import { ToastContainer, toast } from "react-toastify";
// import "./Home.scss"
// import {
//   L1Nine,
//   L2Nine,
//   L3Nine,
//   L4Nine
// } from "../../docs/index"
import Header from "../../component/header";

const ObjectJaavascriptTutes = () => {
  const handleDownloadToastMessage = () => {
    toast.success("Download Successful !", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 200,
      closeButton: false,
      style: {
        background: "#4CAF50", // Background color
        color: "#fff", // Text color
        borderRadius: "8px", // Border radius
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Box shadow
        fontSize: "12px", // Font size
        whiteSpace: "nowrap", // Prevent line breaks
      },
    });
  };
  return (
    <>
      <div className="numbeOfLectoreHome">
        <h2>Object Tutorial</h2>
      </div>
      <div className="homecontainer">
        <div className="homecard">
          <h2>Topic :<span className="topic_highlight"> Object Introduction & Basics</span></h2>
          <p>
            Description : What is Objects & how to create it ?, Benifits of Square brackets, 
            Computed properties, roperty value shorthand, Property names limitations, Property existence test, “in” operator it's benifit,
            The "for..in" loop.
          </p>

          <a href="https://www.youtube.com/watch?v=z6w8vLWQ_lQ&list=PLtLpyd3c90MzjPA1q5UdOgOayqqMufCzJ" target="_blank">
            Watch Video
          </a>
        </div>

      
        
      </div>
    </>
  );
};

export default ObjectJaavascriptTutes;
