const DestructuringQuestions = [
    {
      question: (
        <pre>
          {`
              
              const myName="Prakah"
              const [a,b,c]=myName
              const [a1,,b1,c1]=myName
              
              console.log(a,b,c)
              console.log(a1, b1, c1)
                  `}
        </pre>
      ),
      answer: "P r a, P a k",
      question_type: "Think Output🤔 ",
    },
    {
      question: (
        <pre>
          {`
              const nums=[1,2,3,4,5,6,7,8,9]
              const [a,b,c]=nums
              const [a1,,,b1,c1]=nums
              
              console.log(a,b,c)
              console.log(a1, b1, c1)
              `}
        </pre>
      ),
      answer: "1 2 3, 1 4 5",
      question_type: "Think Output🤔 ",
    },
    {
      question: (
        <pre>
          {`
           
           const nums=new Set([2,2,4,4,4,4,3,4,8,4,5,,4])
           const [a,b,c]=nums
           const [a1,,,b1,c1]=nums
           
           console.log(a,b,c)
           console.log(a1, b1, c1)
              `}
        </pre>
      ),
      answer: "2 4 3, 2 8 5",
      question_type: "Think Output🤔 ",
    },
    {
      question: (
        <pre>
          {`
           
           let arr=["Prakash","Kumar"]
           const [firstName="Teacher", lastName="Trek"]=arr
           console.log(firstName,lastName)
              `}
        </pre>
      ),
      answer: "Prakash Kumar",
      question_type: "Think Output🤔 ",
    },
    {
      question: (
        <pre>
          {`
           
let arr=["Prakash","Kumar","From","Sonpur","Bihar","India"]
const [firstName="Teacher", lastName="Trek",...rest]=arr
const [firstName1="Teacher",,, city,...rest1]=arr
console.log(firstName,lastName)

console.log(firstName1,city)
console.log(rest)
console.log(rest1)
              `}
        </pre>
      ),
      answer: "Prakash Kumar, Prakash Sonpur, ['From','Sonpur','Bihar','India'], ['Bihar','India']",
      question_type: "Think Output🤔 ",
    },
    {
      question: (
        <pre>
          {`
           
           let user = {
            name:"Prakash",
            age:22,
            college:"abc",
            marks:12
         
         
         };
           
           let {name, age, height} = user;
           console.log(name, age, height)
              `}
        </pre>
      ),
      answer: "Prakash 22 undefined",
      question_type: "Think Output🤔 ",
    },
    {
      question: (
        <pre>
          {`
           
           let user = {
            name:"Prakash",
            age:22,
            college:"abc",
            marks:12
         
         
         };
           
           let {name, age, ...rest} = user;
           console.log(name, age, rest)
              `}
        </pre>
      ),
      answer: "Prakash 22 {'college': 'abc','marks': 12}",
      question_type: "Think Output🤔 ",
    },
    {
      question: (
        <pre>
          {`
           
           let user = {
            name:"Prakash",
            age:22,
            college:"abc",
            marks:12,
            address:{
             city:"Sonpur",
             state:"Bihar"
            }
         
         
         };
           
           let {name, age, address, ...rest} = user;
           console.log(name, age, address,rest)
              `}
        </pre>
      ),
      answer: "Prakash 22 {'city': 'sonpur','state': 'Bihar'} {'college':'abc','marks':12}",
      question_type: "Think Output🤔 ",
    },
   
  
  ];
  export { DestructuringQuestions };
  let user = {
    name:"Prakash",
    age:22,
    college:"abc",
    marks:12,
    address:{
     city:"Sonpur",
     state:"Bihar"
    }
 
 
 };
   
   let {name, age, address, ...rest} = user;
   console.log(name, age, address,rest)

  