import React,{useState} from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { allLecture } from '../lecture-list';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';

import { MathClass10L1, MathClass10L2, MathClass10L3, MathClass10L4, MathClass10L5, MathClass10L7, MathClass10L9 } from '../../docs'
import Header from '../../component/header';
import Footer from '../../component/footer/Footer';

const Tenth = () => {
  const [searchField,setSearchField]=useState("")
  const [lectures, setLectures]=useState(allLecture)
  // let filterLecture = allLecture.filter(lecture => lecture.lectureNumber.includes("10th"))
  // console.log("filterlecture1", filterLecture)
  const handlesearchTopic=(e)=>{
    console.log("e",e.target.value)
    const filterLecture=allLecture.filter(lecture=>lecture.chatpterNumber.toLowerCase().includes(e.target.value))
    setLectures(filterLecture)
    console.log("filterlecture2", filterLecture)

    setSearchField(e.target.value)
  }
  const handleDownloadToastMessage = () => {
    toast.success('Download Successful !', {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 200,
      closeButton: false,
      style: {
        background: '#4CAF50', // Background color
        color: '#fff',         // Text color
        borderRadius: '8px',   // Border radius
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Box shadow
        fontSize: '12px',      // Font size
        whiteSpace: 'nowrap',  // Prevent line breaks

      },
    });
  }
  return (
    <>
    <Header />
    <div className='numbeOfLectoreHome'>
        <h2>Class 10th Math All Lecture</h2>

      </div>
      {/* <div className='homecontainer'>
      <div className="homecard">
          <h2>Class 10th Math : L9</h2>
          <p>Arithmetic Progression - Chapter 5</p>
          <p>Topic: Introduction to Arithmetic Progression. Basic of Arithmetic Progression </p>
          <a   download={handleDownloadToastMessage} href={MathClass10L9}>Download Notes</a>
          <a href="https://www.youtube.com/watch?v=ENpn_Jtud7w&list=PLtLpyd3c90MylVPn85Lzymt4iodloAzXw&index=9" target="_blank">Watch Video</a>
        </div>
      <div className="homecard">
          <h2>Class 10th Math : L7</h2>
          <p>Quadratic Equation - Chapter 4</p>
          <p>Topic: Question Practice On quadratic equation</p>
          <a   download={handleDownloadToastMessage} href={MathClass10L7}>Download Notes</a>
          <a href="https://www.youtube.com/watch?v=hJ54NTUPQTE&list=PLtLpyd3c90MylVPn85Lzymt4iodloAzXw&index=7" target="_blank">Watch Video</a>
        </div>
      <div className="homecard">
          <h2>Class 10th Math : L6</h2>
          <p>Quadratic Equation - Chapter 4</p>
          <p>Topic: Solution of a quadratic equation by factorization. Nature of roots</p>
          <a   download="">Not available</a>
          <a href="https://www.youtube.com/watch?v=zrW39W9bztA&list=PLtLpyd3c90MylVPn85Lzymt4iodloAzXw&index=6" target="_blank">Watch Video</a>
        </div>
      <div className="homecard">
          <h2>Class 10th Math : L5</h2>
          <p>Quadratic Equation - Chapter 4</p>
          <p>Topic:Introduction to quadratic equation, standard form of quadratic equation, representation of quadratic equation.</p>
          <a onClick={handleDownloadToastMessage} href={MathClass10L5} download="">Download Notes</a>
          <a href="https://www.youtube.com/watch?v=asplLquLX0w&list=PLtLpyd3c90MylVPn85Lzymt4iodloAzXw&index=5" target="_blank">Watch Video</a>
        </div>
     
      <div className="homecard">
          <h2>Class 10th Math : L1</h2>
          <p>Polynomial - Chapter 2</p>
          <p>Topic: Basics of the polynomial and geometrical meaning of a zero of a linear polynomial.   </p>
          <a onClick={handleDownloadToastMessage} href={MathClass10L1} download="">Download Notes</a>
          <a href="https://www.youtube.com/watch?v=zYotSM3S7Hs&list=PLtLpyd3c90MylVPn85Lzymt4iodloAzXw" target="_blank">Watch Video</a>
        </div>
        <div className="homecard">
          <h2>Class 10th Math : L2</h2>
          <p>Polynomial - Chapter 2</p>
          <p>Topic: Geometrical meaning of a zero of a Quadratic polynomial & Cubic polynomial. With example.  </p>
          <a onClick={handleDownloadToastMessage} href={MathClass10L2} download="">Download Notes</a>
          <a href="https://www.youtube.com/watch?v=LAmmL2hEJjc&list=PLtLpyd3c90MylVPn85Lzymt4iodloAzXw&index=2" target="_blank">Watch Video</a>
        </div>
       
        <div className="homecard">
          <h2>Class 10th Math : L3</h2>
          <p>Polynomial - Chapter 2</p>
          <p>Topic: Relationhip between zeroes and coefficient of a polynomial with proof.  </p>
          <a onClick={handleDownloadToastMessage} href={MathClass10L3} download="">Download Notes</a>
          <a href="https://www.youtube.com/watch?v=AqPajPJcnzY&list=PLtLpyd3c90MylVPn85Lzymt4iodloAzXw&index=3" target="_blank">Watch Video</a>
        </div>
        <div className="homecard">
          <h2>Class 10th Math : L4</h2>
          <p>Polynomial - Chapter 2</p>
          <p>Topic: Question practice on polynomial. Finishing Polynomial </p>
          <a onClick={handleDownloadToastMessage} href={MathClass10L4} download="">Download Notes</a>
          <a href="https://www.youtube.com/watch?v=ASa0gXMDjQI&list=PLtLpyd3c90MylVPn85Lzymt4iodloAzXw&index=4" target="_blank">Watch Video</a>
        </div>
      
      <div className="homecard">
          <h2>Class 10th Math : L5</h2>
          <p>Quadratic Equation - Chapter 4</p>
          <p>Topic:Introduction to quadratic equation, standard form of quadratic equation, representation of quadratic equation.</p>
          <a onClick={handleDownloadToastMessage} href={MathClass10L5} download="">Download Notes</a>
          <a href="https://www.youtube.com/watch?v=asplLquLX0w&list=PLtLpyd3c90MylVPn85Lzymt4iodloAzXw&index=5" target="_blank">Watch Video</a>
        </div>
       
      <div className="homecard">
          <h2>Class 10th Math : L6</h2>
          <p>Quadratic Equation - Chapter 4</p>
          <p>Topic: Solution of a quadratic equation by factorization. Nature of roots</p>
          <a   download="">Not available right now</a>
          <a href="https://www.youtube.com/watch?v=zrW39W9bztA&list=PLtLpyd3c90MylVPn85Lzymt4iodloAzXw&index=6" target="_blank">Watch Video</a>
        </div>
        
      <div className="homecard">
          <h2>Class 10th Math : L7</h2>
          <p>Quadratic Equation - Chapter 4</p>
          <p>Topic: Question Practice On quadratic equation</p>
          <a   download="handleDownloadToastMessage" href={MathClass10L7}>Download Notes</a>
          <a href="https://www.youtube.com/watch?v=hJ54NTUPQTE&list=PLtLpyd3c90MylVPn85Lzymt4iodloAzXw&index=7" target="_blank">Watch Video</a>
        </div>
        <div className="homecard">
          <h2>Class 10th Math : L9</h2>
          <p>Arithmetic Progression - Chapter 5</p>
          <p>Topic: Introduction to Arithmetic Progression. Basic of Arithmetic Progression </p>
          <a   download="handleDownloadToastMessage" href={MathClass10L9}>Download Notes</a>
          <a href="https://www.youtube.com/watch?v=ENpn_Jtud7w&list=PLtLpyd3c90MylVPn85Lzymt4iodloAzXw&index=9" target="_blank">Watch Video</a>
        </div>
     
      </div> */}
      <div style={{display:'flex',justifyContent:'center',}}>   
           <TextField 
           value={searchField}
           onChange={handlesearchTopic}
           style={{width:"50%"}} id="standard-basic" label="Search by topic" variant="standard" />
           <Button variant="outlined" style={{padding:"0px 8px" , marginLeft:"20px"}}>Search</Button>   
              </div>
      <div className="homecontainer">
        {
          lectures.length && lectures.map((lecture, index) => {
            return (
              <>
                <div className='homecard'>
                  <h2>{lecture.lectureNumber}</h2>
                  <p>{lecture.chatpterNumber}</p>
                  <p>{lecture.topicDescription}</p>
                  <a download={handleDownloadToastMessage} target='_blank' href={lecture.pdf}>{lecture.downloadNotes}</a>
                  <a href={lecture.hrefLink}>{lecture.watchVideo}</a>
                </div>
              </>
            )
          })
        }
      </div>
      <Footer/>
    </>
  )
}

export default Tenth