import React,{useState} from "react";
import { ToastContainer, toast } from "react-toastify";
import "./style.scss"
import {PolyfillQuestions} from "./PolyfillQuestion";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import JsCompiler from "../JsCompiler/Landing";

const PolyfillJaavascriptLogicBuilding = () => {
  const numberOfQuestion = PolyfillQuestions.length;
  let explanationVisibilityData = {};
  let copyQuestionIndividual = {};
  for (let index = 0; index < numberOfQuestion; index++) {
    explanationVisibilityData[index + 1] = false;
    copyQuestionIndividual[index + 1] = false;
  }

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [explanationVisibility, setExplanationVisibility] = useState(
    explanationVisibilityData
  );
  const [copyQuestion, setCopyQuestion] = useState(copyQuestionIndividual);

  const toggleExplanation = (questionNumber) => {
    setExplanationVisibility((prevVisibility) => ({
      ...prevVisibility,
      [questionNumber]: !prevVisibility[questionNumber],
    }));
  };

  
  const [questionInEditor, setQuestionInEditor] = useState("");

  const handleOpenInEditor = (index, questionNumber) => {
    const innerText = document.getElementsByClassName("questions_ui_polyfill")[
      index
    ].innerText;
    setQuestionInEditor(innerText);
    // setShowHideEditor(true)
    setOpen(true);
  };
  const [copiedText, setCopiedText] = useState("");
  const copyToClipboard = (index, questionNumber) => {
    // const textToCopy = typeof text === 'object' ? JSON.stringify(text)?.props?.children : text;
    // navigator.clipboard.writeText(textToCopy);
    const innerText = document.getElementsByClassName("questions_ui_function")[
      index
    ].innerText;
    navigator.clipboard.writeText(innerText);

    setCopiedText("copied!");
    /* setTimeout(()=>{
      setCopiedText('copy');
    },2000) */
    setCopyQuestion((prevVisibility) => ({
      ...prevVisibility,
      [questionNumber]: !prevVisibility[questionNumber],
    }));
  };

  return (
    <>
      <div className="logic_building_question_container">
        <h3>Total Question : {PolyfillQuestions.length} </h3>
        <ol>
          {PolyfillQuestions.map((q, index) => {
            return (
              <>
                <li>
                  <span>
                    Q. {index + 1} {q.question_type}
                  </span>

                  <button
                    className="copyQuestionButton"
                    onClick={() => handleOpenInEditor(index, index + 1)}
                  >
                    {copyQuestion[index + 1] && copiedText
                      ? copiedText
                      : "Open In Editor"}
                  </button>
                  <p className="questions_ui_polyfill">{q.question}</p>

                  <button onClick={() => toggleExplanation(index + 1)}>
                    {explanationVisibility[index + 1]
                      ? "Hide Answer"
                      : "See Answer"}
                  </button>
                  {explanationVisibility[index + 1] && (
                    <>
                      <strong style={{ marginLeft: "20px" }}>
                        Expected Output : {q.answer}
                      </strong>
                    </>
                  )}
                </li>
              </>
            );
          })}
        </ol>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="compiler_container">
          <div className="close_compiler_button_container">
            <Button className="close_compiler_button" onClick={handleClose}>
              Close
            </Button>
          </div>

          <JsCompiler questionInEditor={questionInEditor} />
        </div>
      </Modal>
    </>
  );
};

export default PolyfillJaavascriptLogicBuilding;
