// reducer.js

import {
  HOME_PAGE,
  NINTH_PAGE,
  TENTH_PAGE,
  ELEVENTH_PAGE,
  TWELTH_PAGE,
  ADD_DETAIL,
  ADD_DETAIL_TO_MAINPAGE,
  QUIZ_PAGE,
  JAVASCRIPT_TEST,
  SHOW_CIRCULAR_LOADER,
  HIDE_CIRCULAR_LOADER,
  INTERVIEW_QUESTION_OUTPUTBASED,
  IS_LOGGED_IN

} from "../action-constant/index"

export const initialState = {
  isHome: true,
  isNinth: false,
  isTenth: false,
  isEleventh: false,
  isTwelth: false,
  isQuiz: false,
  isJavaScriptTest: false,
  circularLoader: false,
  interviewQuestionOutputBased:false,
  isLoggedIn:false

};

const pageReducer = (state = initialState, action) => {
  switch (action.type) {
    case HOME_PAGE:
      return {
        ...state,
        isHome: true,
        isNinth: false,
        isTenth: false,
        isEleventh: false,
        isTwelth: false,
        isDetailed: false,
        isQuiz: false,
        isJavaScriptTest: false,
        interviewQuestionOutputBased:false

      };
    case SHOW_CIRCULAR_LOADER:
      return {
        ...state,
        circularLoader: true
      };
    case HIDE_CIRCULAR_LOADER:
      return {
        ...state,
        circularLoader: false
      };
    case NINTH_PAGE:
      return {
        ...state,
        isHome: false,
        isNinth: true,
        isTenth: false,
        isEleventh: false,
        isTwelth: false,
        isQuiz: false,
        isJavaScriptTest: false,
        interviewQuestionOutputBased:false



      };
    case TENTH_PAGE:
      return {
        ...state,
        isHome: false,
        isNinth: false,
        isTenth: true,
        isEleventh: false,
        isTwelth: false,
        isQuiz: false,
        isJavaScriptTest: false,
        interviewQuestionOutputBased:false



      };
    case ELEVENTH_PAGE:
      return {
        ...state,
        isHome: false,
        isNinth: false,
        isTenth: false,
        isEleventh: true,
        isTwelth: false,
        isQuiz: false,
        isJavaScriptTest: false,
        interviewQuestionOutputBased:false


      };


    case TWELTH_PAGE:
      return {
        ...state,
        isHome: false,
        isNinth: false,
        isTenth: false,
        isEleventh: false,
        isTwelth: true,
        isQuiz: false,
        isJavaScriptTest: false,
        interviewQuestionOutputBased:false



      };
    case QUIZ_PAGE:
      return {
        ...state,
        isHome: false,
        isNinth: false,
        isTenth: false,
        isEleventh: false,
        isTwelth: false,
        isQuiz: true,
        isJavaScriptTest: false,
        interviewQuestionOutputBased:false



      };
      case INTERVIEW_QUESTION_OUTPUTBASED:
        return {
          ...state,
          isHome: false,
          isNinth: false,
          isTenth: false,
          isEleventh: false,
          isTwelth: false,
          isQuiz: false,
          isJavaScriptTest: false,
          interviewQuestionOutputBased:true
  
  
  
        };
    case JAVASCRIPT_TEST:
      return {
        ...state,
        isHome: false,
        isNinth: false,
        isTenth: false,
        isEleventh: false,
        isTwelth: false,
        isQuiz: false,
        isJavaScriptTest: true,
        interviewQuestionOutputBased:false
      };
      case IS_LOGGED_IN: 
      console.log(action,"payload")
      return{
        ...state,
        isLoggedIn : action.payLoad
      }
    default:
      return state;
  }
};

export default pageReducer;
