import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import CompanyName from "./TopicsName";
import "./companyname.scss";
// import FunctionJaavascriptTutes from '../../pages/js-logic-building/Function';
import PromiseJaavascriptLogicBuilding from "../../pages/js-logic-building/Promise";
import ThisJaavascriptLogicBuilding from "../../pages/js-logic-building/ThisKeywordPrototype";
import ClosureJaavascriptBuilding from "../../pages/js-logic-building/Closure";
import HoistingJaavascriptLogic from "../../pages/js-logic-building/Hoisting";
import Header from "../header";
import ObjectJaavascriptLogicBuilding from "../../pages/js-logic-building/Object";
import FunctionJaavascriptTutes from "../../pages/js-logic-building/Function";
import PolyfillJaavascriptLogicBuilding from "../../pages/js-logic-building/Polyfill";
import EventLoopJaavascriptLogicBuilding from "../../pages/js-logic-building/EventLoop";
import Footer from "../footer/Footer";
import ErrorHandlingJaavascriptLogicBuilding from "../../pages/js-logic-building/ErrorHandling";
import DestructuringJaavascriptLogicBuilding from "../../pages/js-logic-building/Destructuring";
import CallApplyBindJaavascriptLogicBuilding from "../../pages/js-logic-building/CallApplyBind";
import DataTypeJaavascriptLogicBuilding from "../../pages/js-logic-building/DataType";
import ScopeJaavascriptLogicBuilding from "../../pages/js-logic-building/Scope";
import LogicBuildingAddComments from "../LogicBuildingAddComment/LogicBuildingAddComments";
import NoQuestions from "./NoQuestions";
import AsyncAwaitJaavascriptLogicBuilding from "../../pages/js-logic-building/Async-Await";
import ConversionJaavascriptLogicBuilding from "../../pages/js-logic-building/Conversion";
import VariableJaavascriptLogicBuilding from "../../pages/js-logic-building/Variable";
import JsCompiler from "../../pages/JsCompiler/Landing";

const AllCompany = () => {
  let totalCompany = CompanyName.length;
  const [topicNameJS, setTopicNameJS] = useState("Variable");
  const [clickedIndex, setClickedIndex] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [companyName, setCompanyName] = useState(CompanyName);

  // let companyAfterSwearch=CompanyName;
  const handleChange = (e) => {
    console.log("ppp", e.target.value);
    let filteredCompany = CompanyName.filter((company) =>
      company.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setCompanyName(filteredCompany);
    setSearchValue(e.target.value);
  };

  // console.log("companyAfterSwearch",companyAfterSwearch)

  const handleComponent = (topicName, index) => {
    console.log("topicName", topicName);
    setTopicNameJS(topicName);
    setClickedIndex(index);
  };
  return (
    <>
      <Header />
      {/* <JsCompiler /> */}
      {/* <JsCompiler /> */}
      <div className="company_search">
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "60%" },
            textAlign: "center",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="standard-basic"
            value={searchValue}
            onChange={handleChange}
            label="search by topic name"
            variant="standard"
          />
        </Box>
      </div>
      <div className="all_company_interview_questions_container">
        {companyName?.map((each, index) => {
          return (
            <div className="each_company">
              <button
                style={{
                  backgroundColor:
                    clickedIndex === index ||
                    (index === 0 && topicNameJS === "Variable")
                      ? "#555555"
                      : "",
                }}
                onClick={() => {
                  handleComponent(each, index);
                }}
              >
                {each}
              </button>
            </div>
          );
        })}
      </div>
      {/*  {
    topicNameJS==="Function" && <PromiseJaavascriptLogicBuilding/>
  } */}
      {/* {
    topicNameJS===""?<NoQuestions />:null
  } */}
      {topicNameJS === "Destructuring & Rest" && (
        <DestructuringJaavascriptLogicBuilding />
      )}
      {topicNameJS === "Promise" && <PromiseJaavascriptLogicBuilding />}
      {topicNameJS === "This Keyword" && <ThisJaavascriptLogicBuilding />}
      {topicNameJS === "Closure" && <ClosureJaavascriptBuilding />}
      {topicNameJS === "Hoisting" && <HoistingJaavascriptLogic />}
      {topicNameJS === "Object" && <ObjectJaavascriptLogicBuilding />}
      {topicNameJS === "Variable" && <VariableJaavascriptLogicBuilding />}
      {topicNameJS === "Data Types" && <DataTypeJaavascriptLogicBuilding />}
      {topicNameJS === "Function" && <FunctionJaavascriptTutes />}
      {topicNameJS === "Conversion" && <ConversionJaavascriptLogicBuilding />}
      {topicNameJS === "Prototype" && <ObjectJaavascriptLogicBuilding />}
      {topicNameJS === "Call Apply Bind" && (
        <CallApplyBindJaavascriptLogicBuilding />
      )}
      {topicNameJS === "Polyfill" && <PolyfillJaavascriptLogicBuilding />}
      {topicNameJS === "Error Handling" && (
        <ErrorHandlingJaavascriptLogicBuilding />
      )}
      {topicNameJS === "Callback" && <ObjectJaavascriptLogicBuilding />}
      {topicNameJS === "Async-Await" && <AsyncAwaitJaavascriptLogicBuilding />}
      {topicNameJS === "Scope & Closure" && <ScopeJaavascriptLogicBuilding />}
      {topicNameJS === "Event-Loop" && <EventLoopJaavascriptLogicBuilding />}
      {topicNameJS === "Events" && <ObjectJaavascriptLogicBuilding />}
      {topicNameJS === "Document Object Model" && (
        <ObjectJaavascriptLogicBuilding />
      )}
      {topicNameJS === "Browser Object Model" && (
        <ObjectJaavascriptLogicBuilding />
      )}
      {topicNameJS === "setTimeout and setInterval" && (
        <ObjectJaavascriptLogicBuilding />
      )}
      {topicNameJS === "Currying" && <ObjectJaavascriptLogicBuilding />}
      {topicNameJS && <LogicBuildingAddComments />}

      <Footer />
    </>
  );
};

export default AllCompany;
