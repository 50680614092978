import React, { useState } from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import CompanyName from './TopicsName'
// import ShowHideComponent from './showHideConponent';
// import "./companyname.scss"
// import FunctionJaavascriptTutes from '../../pages/js-logic-building/Function';
// import PromiseJaavascriptTutes from '../../pages/js-logic-building/Promise';
// import ThisJaavascriptTutes from '../../pages/js-logic-building/ThisKeywordPrototype';
import FunctionJaavascriptYouTubeTutes from '../../pages/js-youtube-tutes/Function';
import PromiseJaavascriptYouTubeTutes from '../../pages/js-youtube-tutes/Promise';
import Header from '../header';
import Footer from '../footer/Footer';
import ObjectJaavascriptTutes from '../../pages/js-youtube-tutes/Object';
const CommingSoom=()=>{
  return(
    <div className='YouTubeLecture_Comingsoon'>
     
      <h2>Lectures not delivered yet. Details will be available after delivering YouTube lecture.</h2>
    </div>
  )
}


const YouTubeTutorial = () => {
  let totalCompany=CompanyName.length
  const[topicNameJS, setTopicNameJS]=  useState("Variable")
  const [clickedIndex, setClickedIndex] = useState(null);
  const [searchValue, setSearchValue]=useState("")

  const [companyName, setCompanyName]=useState(CompanyName)

// let companyAfterSwearch=CompanyName;
  const handleChange=(e)=>{
    console.log("ppp",e.target.value)
    let filteredCompany=CompanyName.filter(company=>company.toLowerCase().includes(e.target.value))
    setCompanyName(filteredCompany)
    setSearchValue(e.target.value)
  }
  const handleComponent=(topicName,index)=>{
    console.log("topicName",topicName)
    setTopicNameJS(topicName)
    setClickedIndex(index);

  }
  return (
    <>
    <Header />
    <div className='company_search'>
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '60%' }, textAlign:"center"
      }}
      noValidate
      autoComplete="off"
    >
      
      <TextField id="standard-basic" value={searchValue} onChange={handleChange}  label="search by topic name" variant="standard" />
    </Box>
    </div>
     <div className="all_company_interview_questions_container">
      
      {
        companyName.map((each,index)=>{
          return(
            <div className='each_company'>
              <button
              style={{ backgroundColor: clickedIndex === index || (index===0 && topicNameJS==="Variable")? '#555555' : '' }}
               onClick={()=>{handleComponent(each,index)}}>{each}</button>
            </div>
          )
        })
      }
  </div>
  {
    topicNameJS==="Function" && <FunctionJaavascriptYouTubeTutes />
  }
  {
    topicNameJS==="Promise" && <PromiseJaavascriptYouTubeTutes/>
  }
   {
    topicNameJS==="Destructuring" && <CommingSoom/>
  }
   
  {
topicNameJS==="This Keyword" && <CommingSoom />
  }
  {
topicNameJS==="Closure" && <CommingSoom />
  }
  {
topicNameJS==="Hoisting" && <CommingSoom />
  }
   {
topicNameJS==="Object" && <ObjectJaavascriptTutes />
  }
  {
topicNameJS==="Variable" && <CommingSoom />
  }
   {
topicNameJS==="Data Types" && <CommingSoom />
  }
  
  {
topicNameJS==="Conversion" && <CommingSoom />
  }
    {
topicNameJS==="Prototype" && <CommingSoom />
  }
   {
topicNameJS==="Call Apply Bind" && <CommingSoom />
  }
  {
topicNameJS==="Polyfill" && <CommingSoom />
  }
  {
topicNameJS==="Error Handling" && <CommingSoom />
  }
   {
topicNameJS==="Callback" && <CommingSoom />
  }
  {
topicNameJS==="Async-Await" && <CommingSoom />
  }
  {
topicNameJS==="Scope" && <CommingSoom />
  }
  {
topicNameJS==="Event-Loop" && <CommingSoom />
  }
  {
topicNameJS==="Events" && <CommingSoom />
  }
   {
topicNameJS==="Document Object Model" && <CommingSoom />
  }
  {
topicNameJS==="Browser Object Model" && <CommingSoom />
  }
   {
topicNameJS==="setTimeout and setInterval" && <CommingSoom />
  }
  {
topicNameJS==="Currying" && <CommingSoom />
  }
  
 {/*  {
topicNameJS==="This Keyword" && <ThisJaavascriptTutes />
  } */}
  <Footer/>
    </>
   
  )
}

export default YouTubeTutorial