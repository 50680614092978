import React from "react";
import { ToastContainer, toast } from "react-toastify";
// import "./Home.scss"
// import {
//   L1Nine,
//   L2Nine,
//   L3Nine,
//   L4Nine
// } from "../../docs/index"
import Header from "../../component/header";

const PromiseJaavascriptTutes = () => {
  const handleDownloadToastMessage = () => {
    toast.success("Download Successful !", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 200,
      closeButton: false,
      style: {
        background: "#4CAF50", // Background color
        color: "#fff", // Text color
        borderRadius: "8px", // Border radius
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Box shadow
        fontSize: "12px", // Font size
        whiteSpace: "nowrap", // Prevent line breaks
      },
    });
  };
  return (
    <>
      <div className="numbeOfLectoreHome">
        <h2>Promise Tutorial</h2>
      </div>
      <div className="homecontainer">
        <div className="homecard">
          <h2>Topic : <span className="topic_highlight">Error Handling</span></h2>
          <p>
            Description : The “try…catch” syntax, Why Try...catch?, try...catch only works for runtime errors, 
            try...catch works synchronously.
          </p>

          <a href="https://www.youtube.com/watch?v=OdUaQGIECO8&list=PLtLpyd3c90My_af-mz0wFQxquuT6j8nkX" target="_blank">
            Watch Video
          </a>
        </div>

        <div className="homecard">
          <h2>Topic : <span className="topic_highlight">Error Handling Continue...</span></h2>
          <p>
            Description : “Throw” operator,try…catch…finally, return and finally, try...finally, Global catch.
          </p>

          <a href="https://www.youtube.com/watch?v=6kVQtbF-X0c&list=PLtLpyd3c90My_af-mz0wFQxquuT6j8nkX&index=2" target="_blank">
            Watch Video
          </a>
        </div>
        
        <div className="homecard">
          <h2>Topic :<span className="topic_highlight">Synchronous vs asynchronous.</span> </h2>
          <p>
            Description : A glimpse  of Synchronous And  asynchronous.
          </p>

          <a href="https://www.youtube.com/watch?v=wEcBuY0KeFs&list=PLtLpyd3c90My_af-mz0wFQxquuT6j8nkX&index=3" target="_blank">
            Watch Video
          </a>
        </div>
        <div className="homecard">
          <h2>Topic :<span className="topic_highlight">Callback</span> </h2>
          <p>
            Description : What are callbacks?, Callbacks in synchronous functions, Callbacks in asynchronous functions, 
            CallbackHell, Solution of Callback Hell.
          </p>

          <a href="https://www.youtube.com/watch?v=pJ0nEYCEGf4&list=PLtLpyd3c90My_af-mz0wFQxquuT6j8nkX&index=4" target="_blank">
            Watch Video
          </a>
        </div>
        <div className="homecard">
          <h2>Topic :<span className="topic_highlight">Promise</span> </h2>
          <p>
            Description : Here the most demanded topic comes into picture 😃. I did post-mortem of Promise. You will fall in love with promise.
          </p>

          <a href="https://www.youtube.com/watch?v=epKooR3ldG8&list=PLtLpyd3c90My_af-mz0wFQxquuT6j8nkX&index=5" target="_blank">
            Watch Video
          </a>
        </div>
        <div className="homecard">
          <h2>Topic :<span className="topic_highlight">Promise Chaining</span> </h2>
          <p>
            Description : Promise Chaining.
            </p>

          <a href="https://www.youtube.com/watch?v=i06ptMDf3FM&list=PLtLpyd3c90My_af-mz0wFQxquuT6j8nkX&index=6" target="_blank">
            Watch Video
          </a>
        </div>
        <div className="homecard">
          <h2>Topic :<span className="topic_highlight">Promise.all</span> </h2>
          <p>
            Description : I did post-mortem of Promise.all 😃. Must watch
          </p>

          <a href="https://www.youtube.com/watch?v=PytSnFuDFfI&list=PLtLpyd3c90My_af-mz0wFQxquuT6j8nkX&index=7" target="_blank">
            Watch Video
          </a>
        </div>
        
      </div>
    </>
  );
};

export default PromiseJaavascriptTutes;
