const CallApllyBindQuestions = [
    {
      question: (
        <pre>
          {`
              
              console.log("Hello")
              function printMyName(){
                  console.log("Prakash")
              }
              
              
              printMyName.call()
              printMyName.apply()
              let retuenFun=printMyName.bind()
              retuenFun()
                  `}
        </pre>
      ),
      answer: "Prakash, Prakash, Prakash",
      question_type: "Think Output🤔 ",
    },
    {
      question: (
        <pre>
          {`
              console.log("Hello")
              function printMyFullname(lastName){
                  console.log("Prakash",lastName)
              }
              
              
              printMyFullname.call("Kumar")
              printMyFullname.apply("Kumar")
              let retuenFun=printMyFullname.bind("Kumar")
              retuenFun()
              `}
        </pre>
      ),
      answer: "Prakash undefined, Prakash undefined, Prakash undefined",
      question_type: "Think Output🤔 ",
    },
    {
      question: (
        <pre>
          {`
           
           console.log("Hello")
           function printMyFullname(lastName){
               console.log("Prakash",lastName)
           }
           
           
           printMyFullname.call(null,"Kumar")
           printMyFullname.apply(null,["Kumar"])
           let retuenFun=printMyFullname.bind(null,"Kumar")
           retuenFun()
              `}
        </pre>
      ),
      answer: "Prakash Kumar, Prakash Kumar, Prakash Kumar",
      question_type: "Think Output🤔 ",
    },
    {
      question: (
        <pre>
          {`
         
            
         function printMyFullname(){
            console.log(this)
        }
        
        
        const user1={
            name:"x64.prakash.exe"
        }
        const user2={
            name:"teachertrekofficial"
        }
        printMyFullname.call(user1)
        printMyFullname.call(user2)
        
        printMyFullname.apply(user1)
        printMyFullname.apply(user2)
        
        let retuenFun1=printMyFullname.bind(user1)
        retuenFun1()
        
        let retuenFun2=printMyFullname.bind(user2)
        retuenFun2()
                  `}
        </pre>
      ),
      answer: "user1 object user2 object, user1 object user2 object, user1 object user2 object",
      question_type: "Think Output🤔 ",
    },
    {
        question: (
          <pre>
            {`
           
              
           function printMyFullname(age){
            console.log("Name : ",this.name,",", "age :" age)
        }
        
        
        const user1={
            name:"x64.prakash.exe"
        }
        const user2={
            name:"teachertrekofficial"
        }
        printMyFullname.call(user1,22)
        printMyFullname.call(user2,22)
        
        printMyFullname.apply(user1,[22])
        printMyFullname.apply(user2,[22])
        
        let retuenFun1=printMyFullname.bind(user1,22)
        retuenFun1()
        
        let retuenFun2=printMyFullname.bind(user2,22)
        retuenFun2()
                    `}
          </pre>
        ),
        answer: "Name :  x64.prakash.exe , age : 22, Name :  teachertrekofficial , age : 22, Name :  x64.prakash.exe , age : 22, Name :  teachertrekofficial , age : 22, Name :  x64.prakash.exe , age : 22, Name :  teachertrekofficial , age : 22",
        question_type: "Think Output🤔 ",
      },
    
  ];
  export { CallApllyBindQuestions };
  