import React from 'react'
import { ToastContainer, toast } from 'react-toastify';
import {
  L1Nine,
  L2Nine,
  L3Nine,
  L412th,
  L4Nine,
  Matrix12thL2,
  Matrix12thL3
} from "../../docs/index"
import Header from '../../component/header';
import Footer from '../../component/footer/Footer';
const Twelveth = () => {
  const handleDownloadToastMessage = () => {
    toast.success('Download Successful !', {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 200,
      closeButton: false,
      style: {
        background: '#4CAF50', // Background color
        color: '#fff',         // Text color
        borderRadius: '8px',   // Border radius
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Box shadow
        fontSize: '12px',      // Font size
        whiteSpace: 'nowrap',  // Prevent line breaks

      },
    });
  }
  return (
    <>
    <Header />
      <div className='numbeOfLectoreHome'>
        <h2>Class 12 Math All Lecture</h2>

      </div>
      <div className="homecontainer">

        {/* <div className="homecard">
          <h2>Class 12th Math : L1</h2>
          <p>Matrix - Chapter 3</p>
          <p>Topic: Definitio, Order, Types and Equality of Matrix.  Briefly explained. </p>
          <a onClick={handleDownloadToastMessage} href={L412th} download="">Download Notes</a>
          <a href="https://www.youtube.com/watch?v=ZD5aGuRXY0g" target="_blank">Watch Video</a>
        </div>
        <div className="homecard">
          <h2>Class 12th Math : L2</h2>
          <p>Matrix - Chapter 3</p>
          <p>Topic: Question Practice. For Exercise 3.1 </p>
          <a onClick={handleDownloadToastMessage} href={Matrix12thL2} download="">Download Notes</a>
          <a href="https://www.youtube.com/watch?v=HUq2hskDIlk" target="_blank">Watch Video</a>
        </div>
        <div className="homecard">
          <h2>Class 12th Math : L3</h2>
          <p>Matrix - Chapter 3</p>
          <p>Topic: Operation On Matrix. Addition, Difference, Multiply matrix by scaler. Many more.  </p>
          <a onClick={handleDownloadToastMessage} href={Matrix12thL3} download="">Download Notes</a>
          <a href="https://www.youtube.com/watch?v=suXV4nqS5WI" target="_blank">Watch Video</a>
        </div> */}
        Notes will be available after lecture on Youtube!
      
      </div>
      <Footer/>
    </>
  )
}

export default Twelveth