import React from 'react'
import Header from '../../component/header'

const Eleventh = () => {
  return (
    <>
    <Header />
    <div className='lecture_not_available'>Content Will ve available after delivering lecture of class 11. </div>
    </>
  )
}

export default Eleventh