const ErrorHandlingQuestions = [

    {
      question: (
        <pre>
          {`
             
             try {
                let age=22
                let MyName=name
                
            } catch (error) {
                console.log("err",error)
            }
              
  
                  `}
        </pre>
      ),
      answer: "err ReferenceError: name is not defined (Error From catch block)",
      question_type: "Think Output🤔 ",
    },
    {
      question: (
        <pre>
          {`
  
  try {
    let age=22
    console.log("Hi")
    setTimeout(()=>{
        let MyName=name 
    },1000)
    
    
} catch (error) {
    console.log("err",error)
}
  
                  `}
        </pre>
      ),
      answer: "ReferenceError: name is not defined (Error from setTimeout)",
      question_type: "Think Output🤔 ",
    },
   
  ];
  export { ErrorHandlingQuestions };
  