import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ClerkProvider } from "@clerk/clerk-react";
import { Provider } from "react-redux";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";

import { store } from "../src/redux/store/index";
import "./index.css";

import App from "./App";
if (process.env.REACT_APP_RESTRICT_PRODUCTION === 'production') {
  disableReactDevTools();
}// const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY
// console.log("key",PUBLISHABLE_KEY)

// if (!PUBLISHABLE_KEY) {
//   throw new Error("Missing Publishable Key")
// }


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
      {/* <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
      <App />
    </ClerkProvider> */}
    </Provider>
  </React.StrictMode>
);
