import axios from "axios"
import { endPoint } from "../constant/index"

const REACT_APP_URL = process.env.REACT_APP_URL
const REACT_APP_TOKEN=process.env.REACT_APP_TOKEN
const config = {
  headers: { Authorization: `Bearer ${REACT_APP_TOKEN}` }
  };

const HandleUserDetails = async (body) => {

  console.log("local", `${REACT_APP_URL}/${endPoint.userDetails}`)
  return await axios.post(`${REACT_APP_URL}/${endPoint.userDetails}`, body)
}
const handleDeveloperUser = async (body) => {
  
  return await axios.post(`${REACT_APP_URL}/${endPoint.developerUserDetail}`, body)

}
const developerJavaScriptScoreAPI = async (body) => {
  return await axios.post(`${REACT_APP_URL}/${endPoint.developerJavaScriptScore}`, body)

}
const mathScoreApi = async (body) => {
  return await axios.post(`${REACT_APP_URL}/${endPoint.mathExamScore}`, body)

}
const loginWithGoogleApi=async (body)=>{
  return await axios.post(`${REACT_APP_URL}/${endPoint.loginWithGoogle}`, body)

}
const logicBuildingAddComment=async (body)=>{
  return await axios.post(`${REACT_APP_URL}/${endPoint.logicBuildingAddComment}`, body)

}
const logicBuildingGetComment=async ()=>{
  return await axios.get(`${REACT_APP_URL}/${endPoint.logicBuildingGetComment}`)

}
export {
  HandleUserDetails,
  handleDeveloperUser,
  developerJavaScriptScoreAPI,
  mathScoreApi,
  loginWithGoogleApi,
  logicBuildingAddComment,
  logicBuildingGetComment
}