// store.js



import { configureStore } from '@reduxjs/toolkit'
import pageReducer from '../reducer/index'; // Assuming you have a reducer file


export  const store = configureStore({
  reducer: {
    handlePage:pageReducer
  }
})
