import React, { useState } from 'react';
import "./LogicBuildingStyling.scss"
import { logicBuildingAddComment } from '../../api';
import { successToast, warningToast } from '../../constant/helperFunction';

const LogicBuildingAddComments = () => {
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState([]);

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleAddComment = () => {
    let getUserInfo =  JSON.parse(localStorage.getItem("userInfo"));
    console.log("localstorage",getUserInfo)
    let body={
      googleId:getUserInfo["googleId"],
      name:getUserInfo["name"],
      comment:comment
    }
    console.log("bodyyyy",body)

    logicBuildingAddComment(body).then(res=>{
      if(res.data.statusCode===200){
        successToast(res.data.message)
      }
      if(res.data.statusCode===405){
        warningToast(res.data.message)
      }
    }).catch(error=>console.log(error))
    /* if (comment.trim() !== '') {
      setComments([...comments, comment]);
      setComment('');
    } */
  };

  return (
    <div className='logic_building_add_comment'>

      {/* Display questions here */}
      <div>
        {/* Map through and display existing comments */}
        <h3>Comment if you find it helpful 😊! </h3>
        <ul>
          {comments.map((c, index) => (
            <li key={index}>{c}</li>
          ))}
        </ul>
      </div>
 
      <div>
        <textarea
          rows="4"
          cols="50"
          placeholder="Add your comment..."
          value={comment}
          onChange={handleCommentChange}
        ></textarea>
        <br />
        <button onClick={handleAddComment}>Add Comment</button>
      </div>
    </div>
  );
};

export default LogicBuildingAddComments;
