
const PromisesQuestions = [
    {
      question:
        <pre>
          {
            `
           
  const promise1 = Promise.resolve('Resolved!');
  promise1
  .then((result) => console.log(result))
  .catch(error=>console.log("error",error));
                `
          }
        </pre>,
        answer:"Resolved!",
        question_type: "Think Output🤔 ",
  
    },
    {
      question:
        <pre>
          {
            `
          
  const promise1 =new Promise((resolve, reject)=>{
    reject("error")
  });
  promise1
  .then((result) => console.log(result),error=>console.log("error1",error))
  .catch(error=>console.log("error2",error));
                `
          }
        </pre>,
        answer:"error1 Error",
        question_type: "Think Output🤔 ",
  
  
  
    },
    {
      question:
      <pre>
      {
        `
         
  const promise1 =new Promise((resolve, reject)=>{
  resolve("success")  
  reject("error")
  });
  promise1
  .then((result) => console.log(result))
  .catch(error=>console.log(error));
            `
      }
    </pre>,
    answer:"success",
    question_type: "Think Output🤔 ",
  
  
  
     
    },
    {
      question:
      <pre>
      {
        `
 
        const promise = new Promise
        (res => res(2));
        promise.then(v => {
                console.log(v);
                return v * 2;
            })
            .then(v => {
                console.log(v);
                return v * 2;
            })
            .finally(v => {
                console.log(v);
                return v * 2;
            })
            .then(v => {
                console.log(v);
            });
        
            `
      }
    </pre>,
    answer:"2, 4, undefined, 8",
    question_type: "Think Output🤔 ",
  
  
  
    },
    {
      question:
        <pre>
          {
            `
  
  const promise1 = Promise.resolve('Resolved');
  promise1.finally((res) => console.log(res));
  
                `
          }
        </pre>,
        answer:"undefined",
        question_type: "Think Output🤔 ",
  
  
  
      
    },
    {
      question:
        <pre>
          {
            `

  console.log("a")          
  const promise1 = new Promise((res,rej)=>{
    console.log("b")
  });
  console.log("c")
                `
          }
        </pre>,
        answer:"a,b,c",
        question_type: "Think Output🤔 ",
  
  
  
      
    },
    {
      question:
        <pre>
          {
            `

    const promise = new Promise((res,rej)=>{
              rej("hello")
          })
    promise.catch(error => console.log("error",error))
            .then(res => console.log(res))
                `
          }
        </pre>,
        answer:"error hello, undefined",
        question_type: "Think Output🤔 ",
  
  
  
      
    },
    {
        question:
          <pre>
            {
              `
  
    const promise = new Promise((res,rej)=>{
                rej("hello")
            })
    promise.catch(error => 8)
              .then(res => console.log(res))
                  `
            }
          </pre>,
          answer:"8",
          question_type: "Think Output🤔 ",
    
    
    
        
      },
    {
      question:
        <pre>
          {
            `

    const promise = new Promise((res,rej)=>{
              res("hello")
              })
    promise.catch(error => console.log("error",error))
      .then(res => console.log(res));
          
                `
          }
        </pre>,
        answer:"hello",
        question_type: "Think Output🤔 ",
  
  
  
     
    },
    {
      question:
        <pre>
          {
            `
       
  const promise = new Promise((resolve, reject) => {
    reject(Error('Some error occurred'));
  })
  promise.catch(error => console.log(error.message));
  promise.catch(error => console.log(error.message));
                `
          }
        </pre>,
        answer:"Some error occurred, Some error occurred",
        question_type: "Think Output🤔 ",
  
  
  
    
    },
    {
      question:
        <pre>
          {
            `
   
  console.log("a")   
  const promise1 = new Promise((res,rej)=>{
  rej("error")
  console.log("b")
  });
  promise1.then(result => console.log(result)).catch(err=>{
      console.log(err)
  })
  console.log("c");
                `
          }
        </pre>,
        answer:"a, b, c, error",
        question_type: "Think Output🤔 ",
  
  
  
      
    },
  
  
  ];
  export { PromisesQuestions };
  
  
  
  
  