const AsyncAwaitQuestions = [
    {
      question: (
        <pre>
          {`
              
              async function f() {
                return "Prakash";
            }
              
            f().then(res=>console.log(res))
                  `}
        </pre>
      ),
      answer: "Prakash",
      question_type: "Think Output🤔 ",
    },
    {
      question: (
        <pre>
          {`
              async function f() {

                let promise = new Promise((resolve, reject) => {
                  setTimeout(() => resolve("Prakash"), 1000)
                });
              
                let result = await promise; 
              
                console.log(result); 
              }
              
              f();
              `}
        </pre>
      ),
      answer: "Prakash",
      question_type: "Think Output🤔 ",
    },
    {
      question: (
        <pre>
          {`
           
           async function f() {

            let json = await fetch('https://jsonplaceholder.typicode.com/todos/1')
          
            let result = await json.json(); 
          
            console.log(result); 
        }
          
        f();
              `}
        </pre>
      ),
      answer: "Response Object",
      question_type: "Think Output🤔 ",
    },
    {
      question: (
        <pre>
          {`
         
            
         function f() {
            let promise = Promise.resolve("Hello");
            let result = await promise; 
            console.log(result)
        }
        f()
        `}
        </pre>
      ),
      answer: "Uncaught SyntaxError: await is only valid in async functions and the top level bodies of modules",
      question_type: "Think Output🤔 ",
    },
    
  ];
  export { AsyncAwaitQuestions };
  