import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  goToEleventhPage,
  goToHomePage,
  goToNinthPage,
  goToQuizPage,
  goToTenthPage,
  goToTwelfthPage,
  hideCircularLoaderAction,
  showCircularLoaderAction,
} from "../../redux/action-creater/index";
import "./userinfo.scss";
import { HandleUserDetails, mathScoreApi } from "../../api";
import "./quiz.scss";
import UserDetailsForm from "./UserInformation";
import {
  errorToast,
  successToast,
  warningToast,
} from "../../constant/helperFunction";
import { questions } from './mathquestion';
import CircularColorLoader from "../../component/loader";

//     {
//         question: '1. What is the capital of France?',
//         options: [
//             { text: 'Paris', isCorrect: true },
//             { text: 'Berlin', isCorrect: false },
//             { text: 'Rome', isCorrect: false },
//             { text: 'Madrid', isCorrect: false },
//         ],
//     },
//     {
//         question: '2. What is the capital of Bihar?',
//         options: [
//             { text: 'Paris', isCorrect: true },
//             { text: 'Berlin', isCorrect: false },
//             { text: 'Rome', isCorrect: false },
//             { text: 'Madrid', isCorrect: false },
//         ],
//     },
//     {
//         question: '3. What is the capital of UP?',
//         options: [
//             { text: 'Paris', isCorrect: true },
//             { text: 'Berlin', isCorrect: false },
//             { text: 'Rome', isCorrect: false },
//             { text: 'Madrid', isCorrect: false },
//         ],
//     },
//     {
//         question: '4. What is the capital of Haryana?',
//         options: [
//             { text: 'Paris', isCorrect: true },
//             { text: 'Berlin', isCorrect: false },
//             { text: 'Rome', isCorrect: false },
//             { text: 'Madrid', isCorrect: false },
//         ],
//     },
//     {
//         question: '5. What is the capital of Haryana?',
//         options: [
//             { text: 'Paris', isCorrect: true },
//             { text: 'Berlin', isCorrect: false },
//             { text: 'Rome', isCorrect: false },
//             { text: 'Madrid', isCorrect: false },
//         ],
//     }, {
//         question: '6. What is the capital of Haryana?',
//         options: [
//             { text: 'Paris', isCorrect: true },
//             { text: 'Berlin', isCorrect: false },
//             { text: 'Rome', isCorrect: false },
//             { text: 'Madrid', isCorrect: false },
//         ],
//     },
//     // Add more questions as needed
// ];

const Quiz = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [timer, setTimer] = useState(0); // 1 hour in seconds
  const [selectedQuestion, setSelectedQuestion] = useState(currentQuestion + 1);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    isHome,
    isNinth,
    isTenth,
    isEleventh,
    isTwelth,
    isDetailed,
    isQuiz,
    isJavaScriptTest,
    circularLoader,
  } = useSelector((state) => state.handlePage);
  const handleBeforeUnload = (e) => {
    // Display a custom message when the user tries to leave the page
    const message =
      "Are you sure you want to leave this page? Your changes may not be saved.";
    e.returnValue = message; // Standard for most browsers
    return message; // For some older browsers
  };

  useEffect(() => {
    // Add an event listener for the beforeunload event when the component mounts
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    let timerInterval;

    if (timer > 0 && !showScore) {
      timerInterval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    if(timer===0 && showQuiz){

      handleClose()
    }

    return () => {
      clearInterval(timerInterval);
    };
  }, [timer, showScore]);

  const handleAnswerClick = (optionIndex) => {
    const newAnswers = [...userAnswers];
    newAnswers[currentQuestion] =
      newAnswers[currentQuestion] === optionIndex ? null : optionIndex;
    setUserAnswers(newAnswers);

    // const isCorrect = questions[currentQuestion].options[optionIndex].isCorrect;
    // if (isCorrect) {
    //   setScore(score + 1);
    // }
  };

  // const handleAnswerClick = (isCorrect) => {

  //     if (isCorrect) {
  //         setScore(score + 1);
  //     }

  //     const nextQuestion = currentQuestion + 1;

  //     if (nextQuestion < questions.length) {
  //         setCurrentQuestion(nextQuestion);
  //     } else {
  //         // setShowScore(true);
  //     }
  // };

  const handlePreviousClick = () => {
    const prevQuestion = currentQuestion - 1;

    if (prevQuestion >= 0) {
      setCurrentQuestion(prevQuestion);
      setShowScore(false);
      setUserAnswers((prevAnswers) => {
        const updatedAnswers = [...prevAnswers];
        // updatedAnswers[currentQuestion] = null; // Reset the answer for the current question
        return updatedAnswers;
      });
    }
  };

  const handleNextClick = () => {
    const nextQuestion = currentQuestion + 1;

    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
      setUserAnswers((prevAnswers) => {
        const updatedAnswers = [...prevAnswers];
        // updatedAnswers[currentQuestion] = null; // Reset the answer for the current question
        return updatedAnswers;
      });
      setShowScore(false);
    }
  };

  const handleSubmitClick = () => {
    // setShowScore(true);
    // clearInterval(timer); // Stop the timer when the quiz is submitted
  };

  const handleQuestionChange = (event) => {
    const selectedQuestionNumber = parseInt(event.target.value, 10);

    if (
      !isNaN(selectedQuestionNumber) &&
      selectedQuestionNumber > 0 &&
      selectedQuestionNumber <= questions.length
    ) {
      setSelectedQuestion(selectedQuestionNumber);
    }
  };

  const goToSelectedQuestion = () => {
    setCurrentQuestion(selectedQuestion - 1);
    setShowScore(false);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  // user instruction code
  const [userName, setUserName] = useState("");
  const [userGrade, setUserGrade] = useState("");
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [showQuiz, setShowQuiz] = useState(false);
  const [open, setOpen] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const handleClickOpen = () => {
    handleSubmitClick();
    setOpen(true);
    let score = 0;
    console.log(questions, "console");
    console.log(userAnswers, "newAnswers");
    let matchAnsarray = [];
    for (let i = 0; i < questions.length; i++) {
      for (let j = 0; j < questions[i].options.length; j++) {
        if (questions[i].options[j].isCorrect === true) {
          matchAnsarray.push(j);
        }
      }
    }

    for (let i = 0; i < userAnswers.length; i++) {
      if (matchAnsarray[i] === userAnswers[i]) {
        score = score + 1;
      }
    }
    setScore(score);
  };
  // const handleClose = () => {
  //     alert(`Your Score: ${score}/${questions.length}`)
  //     setShowScore(true);
  //     clearInterval(timer); // Stop the timer when the quiz is submitted
  //     navigate("/")
  //     dispatch(goToHomePage())
  //     setOpen(false);
  // };
  const handleClose = () => {
    alert(`Your Score: ${score}/${questions.length}`);
    const response = mathScoreApi({
      userScore: `${score}/${questions.length}`,
      userEmail: userEmail,
    });
    response
      .then((res) => {
        console.log("res", res);
        if (res.data.statusCode === 200) {
          successToast("Test Submitted!");
          // setShowQuiz(true)
          setShowScore(true);
          clearInterval(timer); // Stop the timer when the quiz is submitted
          navigate("/");
          dispatch(goToHomePage());
          setOpen(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        errorToast("Something Went Wrong.");
      });
  };
  const handleCloseWithNode = () => {
    setOpen(false);
  };

  const [userAnswers, setUserAnswers] = useState(
    new Array(questions.length).fill(null)
  );
  const handleStartQuiz = (e) => {
    e.preventDefault();

    if (userName.length < 3) {
      setValidationMessage("Name should have at least three characters.");
      return;
    }

    if (!/^\d{10}$/.test(userPhoneNumber)) {
      setValidationMessage("Please enter a valid phone number.");
      return;
    }

    if (!userEmail.endsWith("@gmail.com")) {
      setValidationMessage("Only gmail.com allowed.");
      return;
    }
    // setValidationMessage('');
    dispatch(showCircularLoaderAction());

    const response = HandleUserDetails({
      userName,
      userGrade,
      userPhoneNumber,
      userEmail,
      userAddress,
    });
    response
      .then((res) => {
        console.log("res", res);
        if (res.data.statusCode === 200) {
          successToast("Test Started!");
          setShowQuiz(true);
          setTimer(1800)

          dispatch(hideCircularLoaderAction());
        } else if (res.data.statusCode === 405) {
          warningToast("Email Already Exist !");
          dispatch(hideCircularLoaderAction());
        }
      })
      .catch((error) => {
        console.log("error", error);
        errorToast("Somethig went wrong.");
        dispatch(hideCircularLoaderAction());
      });
  };

  // user instruction code

  return (
    <>
      {circularLoader ? (
        <CircularColorLoader />
      ) : (
        <>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Warning</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are You Sure You want to submit ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Yes</Button>
              <Button onClick={handleCloseWithNode}>No</Button>

              {/* <Button onClick={handleClose}>Yes</Button> */}
            </DialogActions>
          </Dialog>

          {showQuiz ? (
            <div className="quiz">
              {showScore ? (
                <div className="score">
                  Your Score: {score}/{questions.length}
                </div>
              ) : (
                <div className="question">
                  <div
                    style={{
                      color: timer < 300 ? "red" : "black",
                      textAlign: "right",
                    }}
                  >
                    Time remaining: {formatTime(timer)}
                  </div>
                  <p>Total 20 Question.</p>
                  <h2>{questions[currentQuestion].question}</h2>
                  {/* <div className="options">

                                        {questions[currentQuestion].options.map((option, index) => (
                                            <button
                                                key={index}
                                                onClick={() => handleAnswerClick(option.isCorrect)}
                                            >
                                                {option.text}
                                            </button>
                                        ))}
                                    </div> */}
                  <div className="options">
                    <div className="options">
                      {questions[currentQuestion].options.map(
                        (option, index) => (
                          <label key={index}>
                            <input
                              type="checkbox"
                              checked={userAnswers[currentQuestion] === index}
                              onChange={() => handleAnswerClick(index)}
                            />
                            {option.text}
                          </label>
                        )
                      )}
                    </div>
                  </div>

                  <div className="navigation-buttons">
                    {/* <select value={selectedQuestion} onChange={handleQuestionChange}>
                                            {Array.from({ length: questions.length }, (_, index) => (
                                                <option key={index} value={index + 1}>
                                                    Question {index + 1}
                                                </option>
                                            ))}
                                        </select> */}
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Select Question
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedQuestion}
                        label="select question"
                        onChange={handleQuestionChange}
                      >
                        {/* <MenuItem value={10}>Ten</MenuItem>
                                                    <MenuItem value={20}>Twenty</MenuItem>
                                                    <MenuItem value={30}>Thirty</MenuItem> */}
                        {Array.from(
                          { length: questions.length },
                          (_, index) => (
                            <MenuItem key={index} value={index + 1}>
                              Question {index + 1}
                            </MenuItem>
                          )
                        )}
                      </Select>
                      <button onClick={goToSelectedQuestion}>
                        Go to Question
                      </button>
                    </FormControl>

                    {/* <button onClick={handleSubmitClick}>
                     */}
                    <button
                      onClick={handlePreviousClick}
                      disabled={currentQuestion === 0}
                    >
                      Previous
                    </button>
                    <button
                      onClick={handleNextClick}
                      disabled={currentQuestion === questions.length - 1}
                    >
                      Next
                    </button>
                    <button
                      onClick={() => {
                        // handleSubmitClick()
                        // navigate("/")
                        // dispatch(goToHomePage())
                        handleClickOpen();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="quiz-user-information-container">
              <div className="exam_instruction">
                <h2>Please follow the given instructios.</h2>
                <ul>
                  <li>Each question is of one marks.</li>
                  <li>Every question has exactly one marks</li>
                  <li>One hour of time will be given.</li>
                  <li>Don't cheat from book or internet or anywhere else.</li>
                  <li>
                    Question may have four option and you have to select only
                    one or You will have to write an answer.
                  </li>
                  <li>You will get score after submission.</li>
                  <li>You will get result on your Email.</li>
                </ul>
              </div>

              <form onSubmit={handleStartQuiz}>
                <h2>Please fill this form</h2>
                {validationMessage && (
                  <p
                    style={{
                      color: "red",
                      border: "1px solid red",
                      padding: "8px",
                      borderRadius: "5px",
                    }}
                  >
                    {validationMessage}
                  </p>
                )}

                <label>
                  Name:
                  <br></br>
                  <input
                    type="text"
                    value={userName}
                    onChange={(e) => {
                      setUserName(e.target.value);
                      setValidationMessage("");
                    }}
                    required
                  />
                </label>
                <br />
                <label>
                  Grade: (ex- 9th, 10th, 11th, 12th)
                  <br></br>
                  <input
                    type="text"
                    value={userGrade}
                    onChange={(e) => {
                      setUserGrade(e.target.value);
                      setValidationMessage("");
                    }}
                    required
                  />
                </label>
                <br />
                <label>
                  Phone Number:
                  <br></br>
                  <input
                    type="tel"
                    value={userPhoneNumber}
                    onChange={(e) => {
                      setUserPhoneNumber(e.target.value);
                      setValidationMessage("");
                    }}
                    required
                  />
                </label>
                <br />
                <label>
                  Email:
                  <br></br>
                  <input
                    type="email"
                    value={userEmail}
                    onChange={(e) => {
                      setUserEmail(e.target.value);
                      setValidationMessage("");
                    }}
                    required
                  />
                </label>
                <br />
                <label>
                  Address:
                  <br></br>
                  <textarea
                    value={userAddress}
                    onChange={(e) => {
                      setUserAddress(e.target.value);
                      setValidationMessage("");
                    }}
                    required
                  />
                </label>
                <br />

                <button type="submit">Start Test</button>
                <button
                  type="button"
                  onClick={() => {
                    navigate("/");
                    dispatch(goToHomePage());
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth", // You can use 'auto' instead of 'smooth' for an instant scroll
                    });
                  }}
                >
                  Go To Home
                </button>
              </form>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Quiz;
