import React,{useState} from 'react'
import { dsaTopicsName } from './TopicsNames';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const DSAPromlems = () => {
    const [topicNameJS, setTopicNameJS] = useState("Array");
  const [clickedIndex, setClickedIndex] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [companyName, setCompanyName] = useState(dsaTopicsName);

  // let companyAfterSwearch=CompanyName;
  const handleChange = (e) => {
    console.log("ppp", e.target.value);
    let filteredCompany = dsaTopicsName.filter((company) =>
      company.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setCompanyName(filteredCompany);
    setSearchValue(e.target.value);
  };

  // console.log("companyAfterSwearch",companyAfterSwearch)

  const handleComponent = (topicName, index) => {
    console.log("topicName", topicName);
    setTopicNameJS(topicName);
    setClickedIndex(index);
  };
  return (
   <>
   <div className="company_search">
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "60%" },
            textAlign: "center",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="standard-basic"
            value={searchValue}
            onChange={handleChange}
            label="search by topic name"
            variant="standard"
          />
        </Box>
      </div>
      <div className="all_company_interview_questions_container">
        {dsaTopicsName?.map((each, index) => {
          return (
            <div className="each_company">
              <button
                style={{
                  backgroundColor:
                    clickedIndex === index ||
                    (index === 0 && topicNameJS === "Array")
                      ? "#555555"
                      : "",
                }}
                onClick={() => {
                  handleComponent(each, index);
                }}
              >
                {each}
              </button>
            </div>
          );
        })}
      </div>
   </>
  )
}

export default DSAPromlems