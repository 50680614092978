import React from "react";
import { ToastContainer, toast } from "react-toastify";
// import "./Home.scss"
// import {
//   L1Nine,
//   L2Nine,
//   L3Nine,
//   L4Nine
// } from "../../docs/index"
import Header from "../../component/header";

const FunctionJaavascriptTutes = () => {
  const handleDownloadToastMessage = () => {
    toast.success("Download Successful !", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 200,
      closeButton: false,
      style: {
        background: "#4CAF50", // Background color
        color: "#fff", // Text color
        borderRadius: "8px", // Border radius
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Box shadow
        fontSize: "12px", // Font size
        whiteSpace: "nowrap", // Prevent line breaks
      },
    });
  };
  return (
    <>
      <div className="numbeOfLectoreHome">
        <h2>Function Tutorial</h2>
      </div>
      <div className="homecontainer">
        <div className="homecard">
          <h2>Topic : <span className="topic_highlight">Function Introduction</span></h2>
          <p>
            Description : Function Declaration (Regular Fuuction or Normal
            Function), Function body, Parameters, Default Parameters and
            Returning a value.
          </p>

          <a href="https://www.youtube.com/watch?v=Ic0dgPqT0Lc&list=PLtLpyd3c90MxoQceh71-BhdC7TnqDiyhr" target="_blank">
            Watch Video
          </a>
        </div>

        <div className="homecard">
          <h2>Topic : <span className="topic_highlight">Function expressions</span></h2>
          <p>
            Description : Function expressions, Function is a value, Function
            Expression vs Function Declaration
          </p>

          <a href="https://www.youtube.com/watch?v=9L1FXiOi1Bc&list=PLtLpyd3c90MxoQceh71-BhdC7TnqDiyhr&index=3" target="_blank">
            Watch Video
          </a>
        </div>
        
        <div className="homecard">
          <h2>Topic :<span className="topic_highlight">Arrow Function basics</span> </h2>
          <p>
            Description : Learn when we should use return keyword, when we not need () while creating functiom.
          </p>

          <a href="https://www.youtube.com/watch?v=QMYjN2bfvbQ&list=PLtLpyd3c90MxoQceh71-BhdC7TnqDiyhr&index=4" target="_blank">
            Watch Video
          </a>
        </div>
        
      </div>
    </>
  );
};

export default FunctionJaavascriptTutes;
