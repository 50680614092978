import {
   
    MathClass10L1,
    MathClass10L2,
    MathClass10L3,
    MathClass10L4,
    MathClass10L5,
    MathClass10L7,
    MathClass10L9,

  } from "../../docs/index"
const allLecture=[
    {
        lectureNumber:"Class 10th Math : L9",
        chatpterNumber:"Arithmetic Progression - Chapter 5",
        topicDescription:"Topic: Introduction to Arithmetic Progression. Basic of Arithmetic Progression",
        downloadNotes:"Download Notes",
        watchVideo:"Watch Video",
        pdf:MathClass10L9,
        hrefLink:"https://www.youtube.com/watch?v=ENpn_Jtud7w&list=PLtLpyd3c90MylVPn85Lzymt4iodloAzXw&index=9"
    },
    {
        lectureNumber:"Class 10th Math : L7",
        chatpterNumber:"Quadratic Equation - Chapter 4",
        topicDescription:"Topic: Question Practice On quadratic equation",
        downloadNotes:"Download Notes",
        watchVideo:"Watch Video",
        pdf:MathClass10L7,
        hrefLink:"https://www.youtube.com/watch?v=hJ54NTUPQTE&list=PLtLpyd3c90MylVPn85Lzymt4iodloAzXw&index=7"
    },
    {
        lectureNumber:"Class 10th Math : L6",
        chatpterNumber:"Quadratic Equation - Chapter 4",
        topicDescription:"Topic: Solution of a quadratic equation by factorization. Nature of roots",
        downloadNotes:"Download Notes",
        watchVideo:"Watch Video",
        pdf:"NA",
        hrefLink:"https://www.youtube.com/watch?v=zrW39W9bztA&list=PLtLpyd3c90MylVPn85Lzymt4iodloAzXw&index=6"
    },
    {
        lectureNumber:"Class 10th Math : L5",
        chatpterNumber:"Quadratic Equation - Chapter 4",
        topicDescription:"Topic:Introduction to quadratic equation, standard form of quadratic equation, representation of quadratic equation.",
        downloadNotes:"Download Notes",
        watchVideo:"Watch Video",
        pdf:MathClass10L5,
        hrefLink:"https://www.youtube.com/watch?v=asplLquLX0w&list=PLtLpyd3c90MylVPn85Lzymt4iodloAzXw&index=5"
    },
    {
        lectureNumber:"Class 10th Math : L4",
        chatpterNumber:"Polynomial - Chapter 2",
        topicDescription:"Question practice on polynomial. Finishing Polynomial",
        downloadNotes:"Download Notes",
        watchVideo:"Watch Video",
        pdf:MathClass10L4,
        hrefLink:"https://www.youtube.com/watch?v=ASa0gXMDjQI&list=PLtLpyd3c90MylVPn85Lzymt4iodloAzXw&index=4"
    },
    {
        lectureNumber:"Class 10th Math : L3",
        chatpterNumber:"Polynomial - Chapter 2",
        topicDescription:"Relationhip between zeroes and coefficient of a polynomial with proof.",
        downloadNotes:"Download Notes",
        watchVideo:"Watch Video",
        pdf:MathClass10L3,
        hrefLink:"https://www.youtube.com/watch?v=AqPajPJcnzY&list=PLtLpyd3c90MylVPn85Lzymt4iodloAzXw&index=3"
    },
    {
        lectureNumber:"Class 10th Math : L2",
        chatpterNumber:"Polynomial - Chapter 2",
        topicDescription:"Topic: Geometrical meaning of a zero of a Quadratic polynomial & Cubic polynomial. With example.",
        downloadNotes:"Download Notes",
        watchVideo:"Watch Video",
        pdf:MathClass10L2,
        hrefLink:"https://www.youtube.com/watch?v=LAmmL2hEJjc&list=PLtLpyd3c90MylVPn85Lzymt4iodloAzXw&index=2"
    },
    {
        lectureNumber:"Class 10th Math : L1",
        chatpterNumber:"Polynomial - Chapter 2",
        topicDescription:"Basics of the polynomial and geometrical meaning of a zero of a linear polynomial.",
        downloadNotes:"Download Notes",
        watchVideo:"Watch Video",
        pdf:MathClass10L1,
        hrefLink:"https://www.youtube.com/watch?v=zYotSM3S7Hs&list=PLtLpyd3c90MylVPn85Lzymt4iodloAzXw"
    },
    
    
]



export {
    allLecture
}