export const dsaTopicsName=[
    "Array & Hashing",
    "String",
    "Bit Manipulation",
    "Linked List",
    "Stack",
    "Queue",
    "Recursion",
    "Dynamic Programming",

]