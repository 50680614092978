
const FunctionQuestions = [
   
    {
      question:
        <pre>
          {
            `
            function printMyfullName(firstName,lastName="Singh"){
                console.log("My name is", firstName, lastName)
            }
            printMyfullName("Prakash","Kumar")

                `
          }
        </pre>,
        answer:"My name is Prakash Kumar",
        question_type: "Think Output🤔 ",
  
    },
    {
      question:
        <pre>
          {
            `
            function add(num1=5,num2=num1){
                return num1+num2
            }
            console.log(add())
            `
          }
        </pre>,
        answer:"10",
        question_type: "Think Output🤔 ",
  
  
  
    },
    {
      question:
      <pre>
      {
        `
         
        function sum(num1=num2,num2=5){
            return num1+num2
        }
        console.log(sum())
            `
      }
    </pre>,
    answer:"ReferenceError: Cannot access 'num2' before initialization",
    question_type: "Think Output🤔 ",
    },
    {
      question:
      <pre>
      {
        `
         
        let sum = new Function('a', 'b', 'return a + b');
        console.log( sum(3, 9) ); 
            `
      }
    </pre>,
    answer:"12",
    question_type: "Think Output🤔 ",
    },
    {
      question:
      <pre>
      {
        `
         
        function add1(){
          console.log(arguments)
          }
          add1(33,5,6,8)
            `
      }
    </pre>,
    answer:"[33,5,6,8]",
    question_type: "Think Output🤔 ",
    },
    {
      question:
      <pre>
      {
        `
         
      const add2=()=>{
          console.log(arguments)
      }
      add2(3,4,5)
      
            `
      }
    </pre>,
    answer:"ReferenceError: arguments is not defined",
    question_type: "Think Output🤔 ",
    },
    
  ];
  export { FunctionQuestions };
  
  
  
  
  