// import L1Nine from "../../public/notes/9th-l1.pdf"
// import L2Nine from "../../public/notes/chapter-ex-2.1.pdf"
// import L3Nine from "../../public/notes/math9thl3exercise2.2.pdf"

// export {
//     L1Nine,
//     L2Nine,
//     L3Nine
// }
const L1Nine = process.env.PUBLIC_URL + '/notes/9th-l1.pdf';
const L2Nine = process.env.PUBLIC_URL + '/notes/chapter-ex-2.1.pdf';
const L3Nine = process.env.PUBLIC_URL + '/notes/math9thl3exercise2.2.pdf';
const L4Nine = process.env.PUBLIC_URL + '/notes/Math9l4polynomial.pdf';
const L412th = process.env.PUBLIC_URL + '/notes/matrixL112th.pdf'; //matrixL112th
const Matrix12thL2 = process.env.PUBLIC_URL + '/notes/Matrix12thL2.pdf'; //matrixL112th
const Matrix12thL3 = process.env.PUBLIC_URL + '/notes/Matrix12thL3.pdf'; //matrixL112th
const MathClass10L1 = process.env.PUBLIC_URL + "./notes/polynomial-lecture1class10.pdf"
const MathClass10L2 = process.env.PUBLIC_URL + "./notes/polynomial-lecture2class10.pdf"
const MathClass10L3 = process.env.PUBLIC_URL + "./notes/polynomial-lecture3class10.pdf"
const MathClass10L4 = process.env.PUBLIC_URL + "./notes/polynomial-lecture4class10.pdf"
const MathClass10L5 = process.env.PUBLIC_URL + "./notes/qe-lecture5class10.pdf"
const MathClass10L7 = process.env.PUBLIC_URL + "./notes/qe-lecture7class10.pdf"
const MathClass10L9 = process.env.PUBLIC_URL + "./notes/qe-lecture9class10.pdf"
const DsaPrefixSum = process.env.PUBLIC_URL + "./notes/dsaPrefixSum.pdf"











const Logo = process.env.PUBLIC_URL + '/images/logo.jpg';
const MySquareLogo = process.env.PUBLIC_URL + '/images/mysquarelogo.jpg';


const HeroBg = process.env.PUBLIC_URL + '/images/hero-bg.png';
const Hero = process.env.PUBLIC_URL + '/images/hero.png';
const MyImage = process.env.PUBLIC_URL + "./images/hero.png"
const myImageHome = process.env.PUBLIC_URL + "./images/myimagehome.png"





export {
    L1Nine,
    Matrix12thL2,
    DsaPrefixSum,
    MyImage,
    Matrix12thL3,
    MathClass10L3,
    MathClass10L4,
    MathClass10L5,
    myImageHome,
    MathClass10L7,
    MathClass10L9,
    L2Nine, L3Nine, L4Nine, Hero, HeroBg, Logo, MySquareLogo, L412th, MathClass10L1, MathClass10L2
};